<form [formGroup]="resolveActionForm">
  <div mat-dialog-content class="action-needed-dialog">
    <div class="row action-header-content">
      <div class="col-12 d-flex action-header-wrapper justify-content-between align-items-center">
        <h2 class="center action-header">{{'ACTIONNEEDED.Action needed / Swapped device' | translate}}</h2>
        <span class="material-icons action-close" mat-dialog-close>
          close
          </span>
      </div>
      <hr>
    </div>
    <div class="form-group row">
        <div class="col-12">
          <h2 class="action-device-title my-4">{{'ACTIONNEEDED.This device' | translate}} {{device}} {{'ACTIONNEEDED.has been swapped as an defective device' | translate}}</h2>
          <h3 class="action-resolve-note mb-5">{{'ACTIONNEEDED.Please process with this device in one of the following ways is order to resolve his status.' | translate}}</h3>
        </div>
        <div class="col-md-12 col-lg-12">
          <div>
            <mat-checkbox class="example-margin" formControlName="archive">{{'ACTIONNEEDED.Archive device' | translate}}</mat-checkbox>
            <p>{{'ACTIONNEEDED.The device will be removed from the device list' | translate}}</p>
          </div>
          <div>
              <mat-checkbox class="example-margin" formControlName="service_case">{{'ACTIONNEEDED.Create service case' | translate}}</mat-checkbox>
              <p>{{'ACTIONNEEDED.After filling up the form, a repair ticket will be created for the supplier' | translate}}</p>
          </div>
        </div>
    </div>
  </div>
  <div mat-dialog-actions class="mb-2 mt-5">
    <button mat-button class="ml-auto blue-button" (click)="confirm()">{{'ACTIONNEEDED.Resolve' | translate}}</button>
    <button mat-button mat-dialog-close class="action-cancel">{{'ACTIONNEEDED.Cancel' | translate}}</button>
  </div>
</form>
