<mat-dialog-content class="flex-column p-2">
    <div class="col-12 col-md-12">
      <div class="change-status-border d-flex justify-content-between align-items-center">
        <h2 class="left robot-modal-title m-0">Robot Details</h2>
        <div class="ng-template-text">
            <span mat-dialog-close><img src="../../../../assets/icons/x-button.png"></span>
        </div>
      </div>
      <div class="divider"></div>
      <div class="row">
        <div class="col-12 d-flex flex-column justify-content-center my-3">
          <p class="left mb-0 robot-modal-description">Robot ID: {{robot?.haai_id}}</p>
          <p class="left mb-0 robot-modal-description">Location: {{robot?.address}}</p>
        </div>
      </div>

    </div>
    <div class="col-12 col-md-12">
        <div class="map-container">
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]='17'>
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"
                  ></agm-marker>
            </agm-map>
        </div>
    </div>
</mat-dialog-content>
