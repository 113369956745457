import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";


@Injectable()
export class ClientCategoryService {
  private assetUrl = `${environment.assetUrl}/asset`;
  private adminUrl = `${environment.assetUrl}/admin`;
  constructor(private http: HttpClient) { }

  createBranchCategories(company_id: number, branch_id:number, categories): Observable<any> {
    return this.http.post(`${this.assetUrl}/company/${company_id}/branch/${branch_id}/categories`, categories)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getBranchCategories(reqParams): Observable<any> {
    return this.http.get(`${this.assetUrl}/company/${reqParams.company_id}/branch/${reqParams.branch_id}/categories`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  updateClientCatName(reqParams, category_id: number, category: {name: string}): Observable<any> {
    return this.http.put(`${this.assetUrl}/company/${reqParams.company_id}/branch/${reqParams.branch_id}/categories?category_id=${category_id}`, category)
    .pipe(catchError((error: any) => throwError(error)));
  }
  addVariantsToCategory(reqParams, variants): Observable<any> {
    return this.http.post(`${this.assetUrl}/company/${reqParams.company_id}/branch/${reqParams.branch_id}/category-variants`, variants)
    .pipe(catchError((error: any) => throwError(error)));
  }
 
  getCategoryVariants(reqParams): Observable<any> {
    return this.http.get(`${this.assetUrl}/company/${reqParams.company_id}/branch/${reqParams.branch_id}/category-variants?category_id=${reqParams.category_id}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  deleteCategory(reqParams, category_id: number): Observable<any> {
    return this.http.delete(`${this.assetUrl}/company/${reqParams.company_id}/branch/${reqParams.branch_id}/categories?category_id=${category_id}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
}