import {
  DeviceVariantModel,
  BranchModel,
  MobileProviderModel,
  InsuranceModel,
  CompanyModel,
  EmployeeModel,
  DeviceStatusModel,
  DeviceTypeModel,
  DeviceLifecycleModel,
  DeviceActivityModel,
  ServiceCasesModel,
  ActionNeeded,
  ArchiveReportModel
} from "./";
import { DenormalizedDeviceVariantModel } from './device-denormalized.model';
import { DeviceModelModel } from './device-model.model';
import { DevicePriceModel } from './device-price.model';
import { DeviceSupplierModel } from './device-supplier.model';


export class DeviceModel {
  id: number;
  name: DeviceModelModel;
  serial_number: string;
  imei: string;
  device_status_id: number;
  created_at: string;
  updated_at: string;
  device_variant_id: number;
  device_variant: DeviceVariantModel;
  device_type: DeviceTypeModel;
  branch_id: number;
  branch: BranchModel;
  company_id: number;
  sim: string;
  company: CompanyModel;
  mobile_provider_id: number;
  mobile_provider: MobileProviderModel;
  supplier_name: string;
  insurance_id: number;
  insurance: InsuranceModel;
  employee_id: number;
  employee: EmployeeModel;
  device_status: DeviceStatusModel;
  device_lifecycle: DeviceLifecycleModel;
  device_price: DevicePriceModel;
  device_lifecycle_logs: any;
  device_supplier: DeviceSupplierModel;
  device_activities: DeviceActivityModel;
  service_cases: ServiceCasesModel[];
  denormalized_device_variant: DenormalizedDeviceVariantModel;
  action_neededs: ActionNeeded[];
  assign: boolean;
  device_type_id: number;
  reassign: boolean;
  doa: boolean;
  additional_note: string;
  incoming_device: any;
  storetec_device_ios: [
    {
      storetec_locker_action: {
        type: string
      }
    }
  ];
  outgoing_device: [] = [];
  storetec_swap_requests: [] = [];
  storetec_device_locker: any;
  reference_number: string;
  ticket_id: string;
  phone_number: string;
  order_type: string;
  order_number: number;
  contract_type: string;
  last_employee: EmployeeModel;
  archive_reports: ArchiveReportModel;
  securaze_erasure_file_link: string;
  sim_eid: string;
  sim_eid2: string;
  sim_type: string;
  pendingDeviceIOs: number;
}
