import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-big-price",
  templateUrl: "./big-price.component.html",
  styleUrls: ["./big-price.component.scss"]
})
export class BigPriceComponent implements OnInit {
  @Input() price: string;
  @Input() type: string;
  public decimalPart: string;
  public numberPart: string;

  constructor() {}

  ngOnInit() {
    this.formatValues();
  }

  public formatValues(): void {
    let decimals = this.price.split(".")[1];
    if (decimals === undefined) decimals = "00";
    if (decimals.length === 1) decimals = `${decimals}0`;
    this.decimalPart = decimals;

    this.numberPart = this.price.split(".")[0];
  }
}
