import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, NotificationService } from '../..';

@Component({
  selector: 'app-assignment-successfull',
  templateUrl: './assignment-successfull.component.html',
  styleUrls: ['./assignment-successfull.component.scss'],
})
export class AssignmentSuccessfullComponent implements OnInit {
  public employeeInfo;
  public device;
  public emailNotFound: boolean = false;
  public internalUser;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<AssignmentSuccessfullComponent>,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private authService: AuthenticationService
  ) {
    this.employeeInfo = data?.employee;
    this.device = data?.device;
    this.emailNotFound = this.employeeInfo?.email && true;
    this.internalUser = this.authService.getToken();
   }

  ngOnInit() {
  }

  sendToEmail() {
    let assignmentData = {
      company_id: this.device?.company_id,
      device_id: this.device?.id,
      comment: this.employeeInfo?.comment,
      employee_first_name: this.employeeInfo?.employee_name?.first_name,
      employee_last_name: this.employeeInfo?.employee_name?.last_name,
      employee_email: this.employeeInfo?.employee_name?.email,
    }
    this.notificationService.sendToMailAssigment(assignmentData).subscribe(res => { 
      this.toastr.success(this.translate.instant('NOTIFICATIONS.Email send to employee'));
      this.dialogRef.close()
    });
  }

  confirm() {
    this.dialogRef.close(this.employeeInfo);
  }
}
