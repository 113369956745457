<div>
  <div [ngClass]="{'backdrop': showInfo}" (click)="showInfo=false"></div>
  <div class="panel-wrap" [ngClass]="{'panel-show': showInfo}">
    <div class="panel">
      <div class="nav">
        <h1 class="mb-0"> {{'EMPLOYEFORM.Edit Employee Details' | translate}}</h1> <span (click)="showInfo=false"><img
            src="../../../../assets/icons/x-button.png"></span>
      </div>
      <div class="divider"></div>
      <form class="form ng-pristine example-form" [formGroup]="employeeForm" id="ngForm" (ngSubmit)="updateEmployee()">
        <div class="row">
          <div class="col-12">
            <div class="card card-multi">
              <div class="card-block">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'EMPLOYEFORM.First name' | translate }}</mat-label>
                        <input matInput formControlName="first_name" required>
                        <mat-error *ngIf="employeeForm.get('first_name').hasError('required') && !hasAtLeastOne">
                          {{'EMPLOYEFORM.At least one' | translate }} <strong>{{'EMPLOYEFORM.required' | translate }}</strong>
                          {{'EMPLOYEFORM.fields' | translate}}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'EMPLOYEFORM.Last name' | translate }}</mat-label>
                        <input matInput formControlName="last_name" required>
                        <mat-error *ngIf="employeeForm.get('last_name').hasError('required') && !hasAtLeastOne">
                          {{'EMPLOYEFORM.At least one' | translate }} <strong>{{'EMPLOYEFORM.required' | translate }}</strong>
                          {{'EMPLOYEFORM.fields' | translate}}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-block">
                <div class="row">
                  <div class="col-md-12 col-lg-3">
                    <p>{{'EMPLOYEFORM.Contact Information' | translate }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'EMPLOYEFORM.Email address' | translate }}</mat-label>
                        <input matInput type="email" maxlength="75" formControlName="email">
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'EMPLOYEFORM.Phone number' | translate }}</mat-label>
                        <input matInput type="text" formControlName="phone_number">
                        <mat-error>
                          {{'EMPLOYEFORM.Phone number is not' | translate }} <strong> {{'EMPLOYEFORM.valid' | translate }}</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="card-block p-0">
                      <div class="row">
                        <div class="col-12">
                          <div class="card-header">
                            {{'EMPLOYEFORM.Corporate Information?' | translate }}
                          </div>
                        </div>
                      </div>
                      <div class="divider"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-lg-3">
                        <p>{{'EMPLOYEFORM.Choose branch' | translate }}</p>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="isAllowed(currentUser)">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'EMPLOYEFORM.Branch' | translate }}</mat-label>
                        <mat-select formControlName="branch_id" required>
                          <div *ngIf="isManager">
                            <mat-option *ngFor="let branch of branches" [value]="branch?.id">
                              {{branch?.name}}
                            </mat-option>
                          </div>
                          <div *ngIf="hasPermission">
                            <mat-option *ngFor="let branch of branches" [value]="branch.id">
                              {{branch.name}}
                            </mat-option>
                          </div>
                        </mat-select>
                        <mat-error *ngIf="employeeForm.get('branch_id').hasError('required')">
                          {{'EMPLOYEFORM.Branch is' | translate }} <strong>{{'EMPLOYEFORM.required' | translate
                            }}</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'EMPLOYEFORM.Corporate ID' | translate }}</mat-label>
                        <input matInput formControlName="corporate_id">
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>{{'USERFORM.Cost Center' | translate }}</mat-label>
                            <input matInput formControlName="cost_center">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>{{'USERFORM.Department' | translate }}</mat-label>
                            <input matInput formControlName="department">
                          </mat-form-field>
                        </div>

                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <mat-form-field class="full-width" appearance="outline">
                            <mat-label>{{'USERFORM.Info' | translate }}</mat-label>
                            <input matInput formControlName="info" maxlength="100">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div *ngIf="errorMessage" class="mb-3 errorMessage"> {{errorMessage}} </div>
                    </div>

                    <div class="col-12 ml-auto d-flex px-0 py-3 align-items-center">
                      <p class="small mb-2 label float-left"><span class="text-danger">*</span>{{'EMPLOYEFORM.indicates
                        required information' | translate }} </p>
                      <a class="btn btn-cancel mr-3 ml-auto" role="button" [routerLink]="['/employees/list']"
                        (click)="showInfo = false">{{'EMPLOYEFORM.Cancel' | translate | titlecase}}</a>
                      <button class="btn btn-primary " [disabled]="!submitable">{{'EMPLOYEFORM.Save changes' | translate
                        | titlecase }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
</div>