import { DeviceBrandModel, DeviceTypeModel } from "./index";

export class DeviceModelModel {
  id: number;
  name: string;
  description: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  brand_id: number;
  brand: DeviceBrandModel;
  device_type_id: number;
  device_type: DeviceTypeModel;
  storetec_device_ios: string;
  storetec_swap_requests: string;

}
