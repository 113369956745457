import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-resolver-confirmation',
  templateUrl: './resolver-confirmation.component.html',
  styleUrls: ['./resolver-confirmation.component.scss']
})
export class ResolverConfirmationComponent implements OnInit {
  confirmationInfo;
  pdfFile: Blob;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ResolverConfirmationComponent>,
  ) { 
    this.confirmationInfo = data;
    if (data?.file && !data?.file.includes('<error>S')) {
      this.readBase64File(data?.file);
    }
  }

  readBase64File(file) {
    const decodedFile = Uint8Array.from(
      atob(file)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    const blob = new Blob([decodedFile], { type: 'application/pdf'});
    this.pdfFile = blob;
  }
  saveFile() {
    saveAs(this.pdfFile, 'cost');
  }
  ngOnInit(): void {
  }
  confirm(){
    this.dialogRef.close(this.confirmationInfo);
  }
}
