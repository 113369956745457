import { Pipe, PipeTransform } from "@angular/core";
import { DeviceActivitiesModel } from "../models/device-activities";

@Pipe({ name: "replaceText" })
export class DeviceActivityPipe implements PipeTransform {
  transform(activity_title: string, device_activity: DeviceActivitiesModel[]): string {
    let device_assigments = device_activity.filter(activity => activity?.device_status_id == 2).sort(a => a.id);
    if (device_assigments.length > 0) {
      device_assigments[device_assigments.length-1].title = 'Device activated and assigned to'
    }
    return activity_title;
  }
}
