import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { EmployeeModel, PrintService } from '../..';

@Component({
  selector: 'app-robot-action-confirmation-modal',
  templateUrl: './robot-action-confirmation-modal.component.html',
  styleUrls: ['./robot-action-confirmation-modal.component.scss']
})
export class RobotActionConfirmationModalComponent implements OnInit {
  public qrCode;
  public actionInfo;
  public unsanitizeQrCode;
  public customText: string = null;
  public swap: boolean = false;
  public assign: boolean = false;
  public employee: EmployeeModel = new EmployeeModel();
  public device;
  public email;
  public action: string;
  public address: string;
  public keep_sim: boolean = false;
  public pin: string;
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<RobotActionConfirmationModalComponent>,
    private printService: PrintService
  ) { 
    this.unsanitizeQrCode = data?.qrCode;
    this.customText = data?.text || null;
    this.swap = data?.swap || false;
    this.assign = data?.assign || false;
    this.device = data?.device;
    this.employee = data?.employee
    this.qrCode = this.sanitizer.bypassSecurityTrustResourceUrl(`${data?.qrCode}`);
    this.actionInfo = data;
    this.action = data?.action;
    this.email = data.email;
    this.address = data?.address
    this.pin = data?.pin
  }

  ngOnInit(): void {}

  printQrCode() {
    localStorage.setItem('qrCode', this.unsanitizeQrCode);
      const data = {
        address: this.actionInfo?.address,
        action: this.action,
        pin: this.pin
      }
     this.printService.printDocument('qrCode', data);
  }
  confirm(){
    this.dialogRef.close(this.keep_sim);
  }
}
