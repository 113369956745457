import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastCompletedStepInfo'
})
export class LastCompletedStepInfoPipe implements PipeTransform {

  transform(serviceCase: any, icons: any, service_step_id?: number): any {
    if (!serviceCase || !serviceCase.service_step_completeds || serviceCase.service_step_completeds.length === 0) {
      return null;
    }

    if (service_step_id !== undefined) {
      const matchingIndex = icons.findIndex(icon => icon.id === service_step_id);
      if (matchingIndex !== -1) {
        return icons[matchingIndex];
      }
      return null;
    }

    const lastCompletedStep = serviceCase.service_step_completeds[serviceCase.service_step_completeds.length - 1];
    const matchingIndex = icons.findIndex(icon => icon.id === lastCompletedStep?.service_step_id);

    if (matchingIndex !== -1) {
      return icons[matchingIndex];
    }
    else{
      return icons[icons.length - 1];
    }


  }
}
