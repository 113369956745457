<form [formGroup]="archiveDeviceForm" (ngSubmit)="confirm()" class="p-4">
  <div mat-dialog-content>
    <h2 class="center archive-modal-heading">{{'ARCHIVINGPOPUPREASON.Please stand the reason for archiving this device' | translate}}</h2>
    <div class="form-group row">
        <div class="col-md-12 col-lg-12">
          <div class="form-group">
            <mat-form-field class="full-width">
              <mat-label>{{'ARCHIVINGPOPUPREASON.Reason' | translate}}</mat-label>
              <textarea matInput formControlName="note" maxlength="120"></textarea>
            </mat-form-field>
          </div>
        </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{'ARCHIVINGPOPUPREASON.Cancel' | translate}}</button>
    <button mat-button (click)="confirm()" class="blue-button">{{'ARCHIVINGPOPUPREASON.Archive' | translate}}</button>
  </div>
</form>
