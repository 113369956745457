import { throwError, Observable, Subject } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UserModel } from "../models";
@Injectable()
export class AuthenticationService {
  loggedUser;
  _userActionOccured: Subject<void> = new Subject();
  private backendUrl = `${environment.authUrl}`;
  private assetUrl = `${environment.assetUrl}`
  public sm_company_id;

  constructor(
    private router: Router, 
    private http: HttpClient,
    ) {}

  get userActionOccured(): Observable<void>{ return this._userActionOccured.asObservable()};

  notifyUserAction() {
    this._userActionOccured.next();
  }
  
  isAuthenticated() {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) return true;
    return false;
  }

  public getToken(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.token;
    }
    return null;
  }

  public getCurrentUserId(): string {
    let currentUser = localStorage.getItem("current_user");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return this.loggedUser.id;
    }
    return null;
  }

  login(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/login`, data).pipe(catchError((error: any) => throwError(error)));
  }

  signup(data): Observable<any> {
    return this.http.post(`${this.backendUrl}/signup`, data).pipe(catchError((error: any) => throwError(error)));
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http
      .post(`${this.backendUrl}/forgot-password`, { email: email })
      .pipe(catchError((error: any) => throwError(error)));
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http
      .put(`${this.backendUrl}/forgot-password`, { reset_password_token: token, password: password })
      .pipe(catchError((error: any) => throwError(error)));
  }

  set headers(headers) {
    sessionStorage.setItem('__store', JSON.stringify(headers));
  }

  get headers() {
    return JSON.parse(sessionStorage.getItem('__store'));
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.assetUrl}/auth/user`).pipe(catchError((error: any) => throwError(error)));
  }

  twoFAAuthentication(code,user:UserModel): Observable<any> {
    let pinCode = code?.sms;
    return this.http.post(`${this.backendUrl}/login/2fa/submit/${pinCode}`, user).pipe(catchError((error: any) => throwError(error)));   
  }

  twoFAAuthenticationEmailSend(user:UserModel):Observable <any> {
    return this.http.post(`${this.backendUrl}/login/2fa/send-via-email` , user).pipe(catchError((error: any) => throwError(error)));
  }
  
}
