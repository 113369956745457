<form [formGroup]="resolveActionForm">
    <div mat-dialog-content class="action-needed-dialog">
      <div class="row action-header-content">
        <div class="col-12 d-flex action-header-wrapper justify-content-between align-items-center">
          <h2 class="center action-header mb-0">{{action?.service_step?.name}}</h2>
          <span class="material-icons action-close" mat-dialog-close>
            <img src="../../../../assets/icons/x-button.png"
            />
          </span>
        </div>
        <hr>
      </div>
      <div class="divider"></div>
      <div *ngIf="action?.service_step_id == 8 || action?.service_step_id == 10">
        <div class="form-group row">
            <div class="col-12">
              <h3 class="action-resolve-note mb-5" [innerHTML]="'ACTIONNEEDED.'+action?.service_step?.name | translate"></h3>
            </div>
            <div class="col-md-12 col-lg-12">
              <mat-radio-group formControlName="response" class="d-flex flex-column">
                  <mat-radio-button value="No"> {{'ACTIONNEEDED.no' | translate}} {{actionType}}</mat-radio-button>
                      <mat-radio-group formControlName="reason" *ngIf="resolveActionForm.value.response === 'No'" class="d-flex flex-column ml-4">
                        <mat-radio-button value="{{'ACTIONNEEDED.send' | translate}}" class="my-1"> {{'ACTIONNEEDED.send' | translate}} </mat-radio-button>
                        <mat-radio-button value="{{'ACTIONNEEDED.agree' | translate}} "> {{'ACTIONNEEDED.agree' | translate}} </mat-radio-button>
                      </mat-radio-group>
                  <mat-radio-button  value="Yes" class="mt-2"> {{'ACTIONNEEDED.yes' | translate}} {{actionType}}</mat-radio-button>
              </mat-radio-group>
            </div>
        </div>
        <div class="divider"></div>
        <p class="action-resolve-note">{{'ACTIONNEEDED.info' | translate}} {{actionType}} {{'ACTIONNEEDED.remotely' | translate}}</p>
        <a href="https://support.apple.com/guide/icloud/remove-a-device-mmfc0eeddd/icloud" target="blank" class="blank">
          https://support.apple.com/guide/icloud/remove-a-device-mmfc0eeddd/icloud</a>
      </div>
      <div *ngIf="action?.service_step_id == 11">
        <div class="form-group row">
            <div class="col-12">
                <h3 class="action-resolve-note mb-5" [innerHTML]="'ACTIONNEEDED.'+action?.service_step?.name | translate"></h3>
            </div>
            <div class="col-12">
                <strong>{{'ACTIONNEEDED.inquiry' | translate}}</strong>
            </div>
        </div>
        <div class="divider"></div>
        <mat-label><strong>{{'ACTIONNEEDED.provide info' | translate}}</strong></mat-label>
        <mat-form-field class="full-width" appearance="outline">
            <textarea
                matInput rows="4" 
                formControlName="info"
                placeholder="Write your message"></textarea>
        </mat-form-field>
        <mat-label><strong>{{'ACTIONNEEDED.upload' | translate}}</strong></mat-label>
        <ngx-dropzone (change)="onSelect($event)" class="file-dropzone">
            <ngx-dropzone-label>Drag&drop or <a class="blank">browse </a> files</ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" class="file-preview" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>    
      </div>
      <div *ngIf="action?.service_step_id == 12">
        <div class="form-group row">
            <div class="col-12">
                <h3 class="action-resolve-note mb-0" [innerHTML]="'ACTIONNEEDED.the further' | translate"></h3>
                <div class="action-resolve-note">{{'ACTIONNEEDED.download' | translate}}</div>
            </div>
            <div class="col-12">
               <a class="blank d-flex align-items-center my-2" (click)="saveFile()">
                <mat-icon class="outlined">
                  description
                </mat-icon> 
               COST ESTIMATION (PDF)</a>
            </div>
        </div>
        <div class="divider"></div>
        <mat-label class="action-resolve-note">{{'ACTIONNEEDED.do you want' | translate}}</mat-label>
        <div class="mt-2">
          <p class="device-info">{{'ACTIONNEEDED.device' | translate}}: <strong>
            {{ device?.device_variant?.device_model?.name
            ? device?.device_variant?.device_model?.name
            : device?.denormalized_device_variant?.name}}
            </strong> 
          </p>
          <p class="device-info">SN: <strong>{{device?.serial_number || 'N/A'}}</strong></p>
          <p class="device-info">IMEI: <strong>{{device?.imei || 'N/A'}}</strong></p>
        </div>
        <div class="mt-4">
          <mat-radio-group formControlName="response" class="d-flex flex-column">
              <mat-radio-button value="Yes"> {{'ACTIONNEEDED.repair yes' | translate}} {{actionType}} (repair cost: {{costs?.repair_cost}}€)</mat-radio-button>
              <mat-radio-button  value="No" class="mt-2"> {{'ACTIONNEEDED.repair no' | translate}} {{actionType}} (diagnosis cost: {{costs?.diagnosis_cost}}€)</mat-radio-button>
              <mat-radio-button  value="Disposal" class="mt-2"> {{'ACTIONNEEDED.repair disposal' | translate}} {{actionType}} (disposal cost: {{costs?.scrap_cost}}€)</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="mb-2 mt-5 p-bottom">
      <button mat-dialog-close class="ml-auto mr-2 btn btn-cancel">{{'ACTIONNEEDED.Cancel' | translate | uppercase}}</button>
      <button class="btn btn-primary" (click)="confirm()">{{'ACTIONNEEDED.Resolve' | translate | uppercase}}</button>
    </div>
  </form>
  