<div>
  <div [ngClass]="{'backdrop': showInfo}" (click)="showInfo = false"></div>
  <div class="panel-wrap" [ngClass]="{'panel-show': showInfo}">
    <div class="panel" *ngIf="!qrPin; else qr">
      <div class="d-flex justify-content-between mb-4 px-2">
        <h1>Robot {{ actionInfo?.type | uppercase}}</h1>
        <img class="close-button mb-3" src="../../../../assets/icons/x-button.png" alt="close-btn"
          (click)="closePanel()">
      </div>
      <div class="divider"></div>
      <div class="container">
        <p class="robot-info-status">Status: <span class="robot-info-description"
            [ngClass]="setStatusColor(actionInfo?.status)"> {{actionInfo?.status | titlecase}}</span></p>
      </div>
      <div class="container mt-4">
        <p class="robot-info-description">Request details:</p>
        <p class="robot-info-title">Action type: <span class="robot-info-description"> {{ actionInfo?.type }} </span>
        </p>
        <p class="robot-info-title">Robot ID: <span class="robot-info-description">
            {{ actionInfo?.storetec_locker?.haai_id }}</span></p>
        <p class="robot-info-title">Location: <span class="robot-info-description"> <img class="close-button m-0"
              src="../../../../assets/icons/location-pin-icon.svg"> {{ actionInfo?.storetec_locker?.address }}</span>
        </p>
        <p class="robot-info-title">Branch: <span class="robot-info-description"
            *ngFor="let branch of actionInfo?.storetec_locker?.storetec_locker_branches">
            {{ branch?.branch?.name }}
          </span>
        </p>
        <p class="robot-info-title">User: <span class="robot-info-description"> {{actionInfo?.user?.first_name}}
            {{actionInfo?.user?.last_name}}</span></p>
        <p class="robot-info-title">Date created: <span class="robot-info-description">
            {{actionInfo?.created_at | dateFormat : 'DD/MM/YYYY'}} </span></p>
        <p class="robot-info-title">Date done: <span class="robot-info-description"
            *ngIf="actionInfo?.status === 'done'"> {{actionInfo?.updated_at | dateFormat : 'DD/MM/YYYY'}} </span></p>
      </div>
      <div class="container">
        <div class="divider"></div>
        <div class="d-flex justify-content-between" *ngIf="actionInfo?.type !== 'swap'">
          <p class="robot-info-title">Devices to {{actionInfo?.type}} <span class="robot-info-description">
              {{actionInfo.storetec_device_ios ? actionInfo.storetec_device_ios.length : 'N/A' }} </span></p>
          <a (click)="expend = !expend" class="expendable-btn"> {{ expend ? 'Hide' : 'Show'}} all devices</a>
        </div>
        <div class="row" *ngIf="expend">
          <div class="col-12 d-flex flex-column" *ngFor="let device of actionInfo?.storetec_device_ios; let i = index;">
            <p class="robot-info-title">
              <span class="robot-info-description">{{i + 1}}. </span> Variant: <span class="robot-info-description">
                {{ device?.device?.device_variant?.description}}</span>
            </p>
            <p class="robot-info-title">
              Serial number: <span class="robot-info-description">
                {{ device?.device?.serial_number ? device?.device?.serial_number : 'N/A'}}</span>
            </p>
            <p class="robot-info-title">
              IMEI: <span class="robot-info-description">
                {{ device?.device?.imei ? device?.device?.imei : 'N/A'}}</span>
            </p>
          </div>
        </div>
        <div class="row" *ngIf="actionInfo?.type === 'swap'">
          <div class="col-12 d-flex flex-column">
            <p class="robot-info-description">Swapping</p>
            <p class="robot-info-title">
              Variant: <span class="robot-info-description">
                {{actionInfo?.storetec_swap_request?.incoming_device?.device_variant?.description}}</span>
            </p>
            <p class="robot-info-title">
              Serial number: <span class="robot-info-description">
                {{actionInfo?.storetec_swap_request?.incoming_device?.serial_number}}</span>
            </p>
            <p class="robot-info-title">
              IMEI: <span class="robot-info-description">
                {{actionInfo?.storetec_swap_request?.incoming_device?.imei}} </span>
            </p>
            <p class="robot-info-description">with:</p>
            <p class="robot-info-title">
              Variant: <span class="robot-info-description">
                {{actionInfo?.storetec_swap_request?.outgoing_device?.device_variant?.description}}</span>
            </p>
            <p class="robot-info-title">
              Serial number: <span class="robot-info-description">
                {{actionInfo?.storetec_swap_request?.outgoing_device?.serial_number}}</span>
            </p>
            <p class="robot-info-title">
              IMEI: <span class="robot-info-description"> {{
                actionInfo?.storetec_swap_request?.outgoing_device?.imei ?
                actionInfo?.storetec_swap_request?.outgoing_device?.imei :
                'N/A'
                }} </span>
            </p>
          </div>
        </div>
      </div>

    </div>
    <ng-template #qr>
      <div class="panel">
        <div class="d-flex justify-content-between mb-4 px-2">
          <h1>QR and PIN code </h1>
          <img class="close-button mb-3" src="../../../../assets/icons/x-button.png" alt="close-btn"
            (click)="closePanel()">
        </div>
        <div class="divider"></div>
        <div class="container d-flex justify-content-center">
          <img [src]="qrCode" alt="">
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <p class="robot-pin">PIN code: <span>{{actionInfo.pin}}</span></p>
          <button class="btn btn-primary d-flex align-items-center mb-4" (click)="print()">
            <span class="material-icons mr-2">
              print
            </span>
            PRINT QR CODE</button>  
        </div>
        <div class="text-center">
          <span class="robot-info-description">You can print code and scan it at following location:</span>
          <p class="text-blue">
            <img src="/assets/icons/map-pin.png" alt="">   
            {{actionInfo?.storetec_locker?.address}}</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>