import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AgmCoreModule } from "@agm/core";
import {
  AppDateAdapter,
  APP_DATE_FORMATS
} from "src/app/shared/helpers/format-datepicker";


// language translate ngx-translate
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// ANGULAR MATERIAL
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";


@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl
    }
  ],
  imports: []
})
export class MaterialModule {}


import { TokenInterceptor } from './shared/interceptors';

// ROUTING
import { AppRoutes } from "./app.routing";

// GUARDS AND SERVICES
import {
  AuthGuard,
  AuthenticationService,
  UserService,
  BranchService,
  DashboardService,
  TranslationService,
  DeviceService,
  RobotService,
  EmployeeService,
  PrintService,
  UnifiedVariantsService,
  NotificationService,
  PaginationService,
  ScriptLoaderService,
  } from "./shared";


// COMPONENTS
import { AppComponent } from "./app.component";
import { AuthenticationComponent } from "./authentication/authentication.component";
import { AdminComponent } from "./admin/admin.component";
import { NavbarComponent } from "./admin/navbar/navbar.component";
import { SidebarComponent } from "./admin/sidebar/sidebar.component";
import { DeleteModalComponent } from './shared/modal-dialogs/delete-modal/delete-modal.component';
import { ModifyDetailsComponent } from './shared/modal-dialogs/modify-details/modify-details.component';
import { InactiveModalComponent } from './shared/modal-dialogs/inactive-modal/inactive-modal.component';
import { BreadscrumbComponent } from './shared/breadscrumb/breadscrumb.component';
import { ModifyInsuranceDetailsComponent } from './shared/modal-dialogs/modify-insurance-details/modify-insurance-details.component';
import { ModifyDeviceSpecComponent } from './shared/modal-dialogs/modify-device-spec/modify-device-spec.component';
import { ChangeBranchModalComponent } from './shared/modal-dialogs/change-branch-modal/change-branch-modal.component';
import { ArchiveDeviceComponent } from './shared/modal-dialogs/archive-device/archive-device.component';
import { ServiceToolModalComponent } from './shared/modal-dialogs/service-tool-modal/service-tool-modal.component';
import { AssignmentSuccessfullComponent } from './shared/modal-dialogs/assignment-successfull/assignment-successfull.component';
import { CreateBranchCategoriesComponent } from "./shared/components/create-branch-categories/create-branch-categories.component";
import { ActionNeededDialogComponent } from './shared/modal-dialogs/action-needed-dialog/action-needed-dialog.component';
import { RobotModalComponent } from "./shared/modal-dialogs/robot-modal/robot-modal.component";
import { RobotDetailsComponent } from "./shared/modal-dialogs/robot-details/robot-details.component";
import { ActionNeededResolverComponent } from "./shared/modal-dialogs/action-needed-resolver/action-needed-resolver.component";
import { RobotActionConfirmationModalComponent } from "./shared/modal-dialogs/robot-action-confirmation-modal/robot-action-confirmation-modal.component";
import { CancelDeviceScComponent } from "./shared/modal-dialogs/cancel-device-sc/cancel-device-sc.component";
import { TwoFactorAuthenticationComponent } from "./shared/modal-dialogs/two-factor-authentication/two-factor-authentication.component";
import { AreYouSureModalComponent } from "./shared/modal-dialogs/are-you-sure-modal/are-you-sure-modal.component";
import { ActionNeededCommentComponent } from "./shared/modal-dialogs/action-needed-comment/action-needed-comment.component";
import { ResolverConfirmationComponent } from "./shared/modal-dialogs/resolver-confirmation/resolver-confirmation.component";
import { ExtendLifecycleModalComponent } from "./shared/modal-dialogs/extend-lifecycle-modal/extend-lifecycle-modal.component";


import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//PIPES
import { MainPipe } from './shared/pipes/main-pipe.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ComponentsModule } from "./shared/components/shared.module";
import { ClientCategoryService } from "./shared/services/client-category.service";
import { StyleConfiguratorService } from "./shared/services/style-configurator.service";
import { CustomPaginatorIntl } from "./shared/helpers/translate-paginator-intl";

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    AdminComponent,
    NavbarComponent,
    SidebarComponent,
    DeleteModalComponent,
    ModifyDetailsComponent,
    InactiveModalComponent,
    BreadscrumbComponent,
    ModifyInsuranceDetailsComponent,
    ModifyDeviceSpecComponent,
    ChangeBranchModalComponent,
    ArchiveDeviceComponent,
    ServiceToolModalComponent,
    AssignmentSuccessfullComponent,
    CreateBranchCategoriesComponent,
    ActionNeededDialogComponent,
    RobotModalComponent,
    RobotActionConfirmationModalComponent,
    RobotDetailsComponent,
    AreYouSureModalComponent,
    ActionNeededResolverComponent,
    ActionNeededCommentComponent,
    ResolverConfirmationComponent,
    CancelDeviceScComponent,
    TwoFactorAuthenticationComponent,
    ExtendLifecycleModalComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MainPipe,
    NgxDropzoneModule,
    ComponentsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCl-D6yMBYPVzkWuyA3aa3JZe9suJZrVeQ',
      libraries: ['places']
    }),
    // ngx translate configuration
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
           }
       })
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    AuthenticationService,
    UserService,
    BranchService,
    DashboardService,
    TranslationService,
    DeviceService,
    RobotService,
    EmployeeService,
    PrintService,
    UnifiedVariantsService,
    ClientCategoryService,
    NotificationService,
    StyleConfiguratorService,
    PaginationService,
    ScriptLoaderService
  ],
  entryComponents: [
    DeleteModalComponent,
    ModifyDetailsComponent,
    InactiveModalComponent,
    ModifyInsuranceDetailsComponent,
    ModifyDeviceSpecComponent,
    ChangeBranchModalComponent,
    ArchiveDeviceComponent,
    ServiceToolModalComponent,
    AssignmentSuccessfullComponent,
    CreateBranchCategoriesComponent,
    ActionNeededDialogComponent,
    RobotModalComponent,
    RobotActionConfirmationModalComponent,
    RobotDetailsComponent,
    AreYouSureModalComponent,
    ExtendLifecycleModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}



// ngx translate configuration
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
