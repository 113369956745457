import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-extend-lifecycle-modal',
  templateUrl: './extend-lifecycle-modal.component.html',
  styleUrls: ['./extend-lifecycle-modal.component.scss']
})
export class ExtendLifecycleModalComponent implements OnInit {
  lifecycleForm: FormGroup;
  customText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExtendLifecycleModalComponent>,
    ) { 
      this.customText = data.text;
    }

  ngOnInit(): void {
    this.lifecycleForm = this.fb.group({
      extend_date: [Validators.required]
    })
  }

  dateFilter = (date: Date) => {
    return moment(date).format('YYYY-MM-DD') >= moment(moment()).format('YYYY-MM-DD')
  };

  confirm() {
    if(this.lifecycleForm.valid){
      this.dialogRef.close(this.lifecycleForm.value);
    }
  }
}
