import { FormControl, FormGroup } from '@angular/forms';

export function isFormDirtyAndChanged(form: FormGroup, initialValues: any): boolean {
  function isControlDirtyAndChanged(control: FormControl, initialValue: any): boolean {
    return control.dirty && control.value !== initialValue;
  }

  function isFormGroupDirtyAndChanged(formGroup: FormGroup, initialValues: any): boolean {
    for (const controlName in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlName)) {
        const control = formGroup.controls[controlName];
        const initialValue = initialValues[controlName];

        if (Array.isArray(control.value) && Array.isArray(initialValue)) {
          if (!arraysEqual(control.value, initialValue)) {
            return true;
          }
        } else if (control instanceof FormGroup) {
          if (isFormDirtyAndChanged(control, initialValue)) {
            return true;
          }
        } else if (control instanceof FormControl) {
          if (isControlDirtyAndChanged(control, initialValue)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  function arraysEqual(a: any[], b: any[]): boolean {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }

  return isFormGroupDirtyAndChanged(form, initialValues);
}
