import { Pipe, PipeTransform } from "@angular/core";
import { ActionNeeded, DeviceModel } from "..";

@Pipe({ name: "hasUnresolvedAction"})
export class UnresolvedActionPipe implements PipeTransform {
    transform(device: any, blockerSteps?: boolean, message?: boolean): boolean {
        if (!device?.action_neededs) {
            return false;
        }

        if (message) {
            return device?.action_neededs.filter((action: ActionNeeded) => !action.resolved)[0];
        }

        if (blockerSteps) {
            return !!device.action_neededs.find(
                (action: ActionNeeded) => !action.resolved && action.type.startsWith('service-case')
            );
        }
        
        return !!device.action_neededs.find(
            (action: ActionNeeded) => !action.resolved
        );
    }
}