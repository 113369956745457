import { Injectable } from '@angular/core';

@Injectable()
export class PaginationService {
    private settings: { [key: string]: {perPage: number, currentPage: number} } = {};

    constructor() { }

    getItemsPerPage() {
        return JSON.parse(sessionStorage.getItem(`pagination`));
    }

    setItemsPerPage(pageKey: string, page: number, limit: number): void {
        this.settings[pageKey] = {
            perPage: page,
            currentPage: limit
        }
        const storedSettings = JSON.parse(sessionStorage.getItem('pagination')) || {};
        const newSettings = {...storedSettings, ...this.settings};
        sessionStorage.setItem('pagination', JSON.stringify(newSettings));
    }

    clearSettings() {
        this.settings = {};
    }
}