import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { EmployeeModel } from "../models";

@Injectable()
export class EmployeeService {
  private assetUrl = `${environment.assetUrl}/asset`;
  public perPage: number = 10;
  constructor(private router: Router, private http: HttpClient) {}

  getEmployees(
    company_id: number,
    page: number,
    limit: number,
    filterObject: any
  ): Observable<any> {
    let query = `?page=${page}&limit=${limit}`;

    let params = new HttpParams();
    Object.keys(filterObject).forEach(k => {
      if (filterObject[k] && typeof filterObject[k] != 'object') {
          params = params.set(k, filterObject[k]);
      }});
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/employee${query}`, {params})
      .pipe(catchError((error: any) => throwError(error)));
  }
  getEmployeesCount(company_id: number, branch_id: number | string, ): Observable<any> {
    let query = branch_id ? `?branch_id=${branch_id}` : "?"
    return  this.http.get(`${this.assetUrl}/company/${company_id}/employee/count${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getEmployeeById(
    company_id: number,
    branch_id: number,
    employee_id: number
  ): Observable<any> {
    const query = branch_id !== null ? `?branch_id=${branch_id}` : "";
    return this.http
      .get(
        `${this.assetUrl}/company/${company_id}/employee/${employee_id}${query}`
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  createEmployee(company_id: number, employee: EmployeeModel): Observable<any> {
    return this.http
      .post(`${this.assetUrl}/company/${company_id}/employee`, employee)
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateEmployee(company_id: number, employee: EmployeeModel): Observable<any> {
    return this.http
      .put(
        `${this.assetUrl}/company/${company_id}/employee/${employee.id}`,
        employee
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateEmployeeStatus(
    company_id: number,
    employee: EmployeeModel,
    employeeId
  ): Observable<any> {
    return this.http
      .put(
        `${this.assetUrl}/company/${company_id}/employee/${employeeId}`,
        employee
      )
      .pipe(catchError((error: any) => throwError(error)));
  }

  deleteEmployee(company_id: number, employeeId: number): Observable<any> {
    return this.http
      .delete(`${this.assetUrl}/company/${company_id}/employee/${employeeId}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  // searchEmployees(
  //   company_id: number,
  //   branch_id: number,
  //   name: string,
  //   status: string,
  // ): Observable<any> {
  //   const query =
  //     branch_id !== null
  //       ? `?branch_id=${branch_id}&name=${name}&status=${status}`
  //       : `?name=${name}&status=${status}`;
  //   return this.http
  //     .get(`${this.assetUrl}/company/${company_id}/employee/search${query}`)
  //     .pipe(catchError((error: any) => throwError(error)));
  // }
  searchEmployees(
    company_id: number,
    filters: Object
  ): Observable<any> {

    let params = new HttpParams();

    Object.keys(filters).forEach(k => {
      if (filters[k] && typeof filters[k] != 'object') {
          params = params.set(k, filters[k]);
      }});

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/employee/search`, {params})
      .pipe(catchError((error: any) => throwError(error)));
  }

  exportEmployeeList(company_id:number, filterObject?): Observable<any> {
    let params = new HttpParams();
    Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
            params = params.set(k, filterObject[k]);
        }
    });
    return this.http.get(`${this.assetUrl}/company/${company_id}/employee/export`, {params})
    .pipe(catchError((error: any) => throwError(error)));
  }
}
