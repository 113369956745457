import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable, BehaviorSubject } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { IStyleConfigurator } from '../models/interfaces';

@Injectable()

export class StyleConfiguratorService {
  private adminUrl = `${environment.assetUrl}/admin/configuration`;
  public confInit = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) { }

  getStyleConfiguration(confName: string): Observable<any> {
    return this.http.get(`${this.adminUrl}?name=${confName}`, {observe: 'response'})
    .pipe(catchError((error: any) => throwError(error)));
  }

  get configuration(): IStyleConfigurator {
    return JSON.parse(localStorage.getItem('conf'));
  }
 
  set configuration(configuration: IStyleConfigurator) {
    localStorage.setItem('conf', JSON.stringify(configuration));
  } 

 setupConfiguration(configuration) {
    Object.keys(configuration).forEach(k => {
      if (configuration[k] !== null) {
        document.documentElement.style.setProperty(`--${k}`, `${k == 'logo' ? `url(${environment.assetUrl}/${configuration.logo})` : configuration[k]}`);
      }
    })
  }
}
