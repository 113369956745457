<form [formGroup]="modifyDeviceSpec" (ngSubmit)="confirm()">
    <div mat-dialog-content>
      <p>Device Spec</p>
      <div class="form-group row">
        <div class="col-md-12 col-lg-12">
            <mat-form-field class="full-width">
              <input matInput  placeholder="IMEI" formControlName="imei" />
          </mat-form-field>
          <mat-error>
              <span *ngIf="modifyDeviceSpec.get('imei').touched && !hasAtLeastOne" class="mr-auto custom-error">
                One of the fields is <strong class="bold">required</strong>
            </span>
          </mat-error>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12 col-lg-12">
            <div class="form-group">
                <mat-form-field class="full-width">
                  <input matInput  placeholder="Serial number" formControlName="serial_number" />
                </mat-form-field>
                <mat-error>
                    <span *ngIf="modifyDeviceSpec.get('serial_number').touched && !hasAtLeastOne" class="mr-auto custom-error">
                      One of the fields is <strong class="bold">required</strong>
                    </span>
                </mat-error>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <mat-form-field class="full-width">
                <mat-select placeholder="Supplier name" formControlName="device_supplier_id">
                  <mat-option *ngFor="let deviceSupp of deviceSuppliers" [value]="deviceSupp.id">
                    {{deviceSupp.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-button mat-dialog-close class="btn-link">Cancel</button>
      <button mat-button (click)="confirm()" class="blue-button">Update</button>
    </div>
    </form>
