
<div class="error_page">


    <div class="error_in">
        <div class="error_form error-box">
            <br />
            <img src="../../../../assets/img/404.png" class="sign_h1">
            <br>
            <p class="error-info">The page you’re looking for appears to have been moved,
                <br><br> <span class="error-info-2"> deleted, or doesn't exist. </span>
            </p>


            <button class="justify-content-center btn-back">
                <a class="back-route" [routerLink]="['/dashboard']">back to dashboard</a>
            </button>
        </div>
    </div>
</div>