<div mat-dialog-content class="action-needed-dialog">
    <div class="row action-header-content">
      <div class="col-12 d-flex action-header-wrapper justify-content-between align-items-center">
        <h2 class="center action-header mb-0">{{confirmationInfo?.text}}</h2>
        <span class="material-icons action-close" mat-dialog-close>
          <img src="../../../../assets/icons/x-button.png"
          />
        </span>
      </div>
      <hr>
    </div>
    <div class="divider"></div>
    <div class="row">
        <div class="col-12">
            <p>{{confirmationInfo?.response}}</p>
            <div *ngIf="confirmationInfo?.action?.service_step_id === 8 || confirmationInfo?.action?.service_step_id === 10">
                <span>Your response:</span>
                <p class="res-message">{{confirmationInfo?.reason}}</p>
            </div>
        </div>
    </div>
    <div class="divider"></div>
    <div class="row" *ngIf="confirmationInfo?.action?.service_step_id === 8 || confirmationInfo?.action?.service_step_id === 10">
        <div class="col-12">
            <p>Our service technician will resolve this issue as soon as possible.</p>
        </div>
    </div>
    <div class="row" *ngIf="confirmationInfo?.action?.service_step_id === 12">
        <div class="col-12">
            <p class="res-message">Cost: <span class="price">{{confirmationInfo?.cost}}€</span></p>
            <mat-checkbox>I agree to the Terms & Conditions.</mat-checkbox>
        </div>
        <div class="col-12" *ngIf="!confirmationInfo?.cost">
            <a class="blank d-flex align-items-center my-2" (click)="saveFile()">
             <mat-icon class="outlined">
               description
             </mat-icon> 
            COST ESTIMATION (PDF)</a>
            <mat-checkbox>I agree to the Terms & Conditions.</mat-checkbox>
         </div>
    </div>
</div>
<div mat-dialog-actions class="mb-2 mt-5">
    <button mat-dialog-close class="ml-auto mr-2 btn btn-cancel">{{'ACTIONNEEDED.Cancel' | translate | uppercase}}</button>
    <button class="btn btn-primary" (click)="confirm()">{{'ACTIONNEEDED.confirm' | translate | uppercase}}</button>
</div>