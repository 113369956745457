
<div class="not-found">
    <div>
        <div class="not-found-logo">
            <img [src]="logoPath" class="img-fluid logo-img">
        </div>
        <div class="not-found-info">
            <p class="not-found-info__text">
                Fehler bei Ihren Kontoeinstellungen.
                Bitte melden Sie sich an das Support-Team.</p>
        </div>
        <button class="justify-content-center btn-back mt-4" (click)="onAppLeave()">Zurück zu Mein A1 Business</button>
    </div>
</div>