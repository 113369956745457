import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-additional-comment',
  templateUrl: './additional-comment.component.html',
  styleUrls: ['./additional-comment.component.scss']
})
export class AdditionalCommentComponent implements OnInit {
  @Output() commentEmmiter = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }


}
