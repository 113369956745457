import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { DeviceService, UserService } from '../../services';
import { UserModel, DeviceModel, InsuranceModel, MobileProviderModel, InsuranceSupplierModel } from '../../models';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-modify-insurance-details',
  templateUrl: './modify-insurance-details.component.html',
  styleUrls: ['./modify-insurance-details.component.scss']
})
export class ModifyInsuranceDetailsComponent implements OnInit {
  modifyInsuranceDetails: FormGroup;
  private deviceId: number;
  public device: DeviceModel = new DeviceModel();
  private currentUser = new UserModel();
  public insurances: InsuranceModel[] = [];
  public mobileProviders: MobileProviderModel[] = [];
  public insuranceSuppliers: InsuranceSupplierModel[] = [];
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: {route: ActivatedRoute},
    private dialogRef: MatDialogRef<ModifyInsuranceDetailsComponent>,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService

  ) {
    data.route.params.subscribe(params => {this.deviceId = params.id});
   }

  ngOnInit() {

    this.getCurrentUser();
    this.modifyInsuranceDetails = this.fb.group({
      insurance_id: [],
      insurance_supplier_id: [{value: '', disabled: true}],
      mobile_provider_id: [],
    })
    this.getInsurances();
    this.getMobileProviders();
    this.getInsuranceSuppliers();


  }
  getCurrentUser() {
    this.userService.getCurrentUser().subscribe(res => {
      this.currentUser = res;
      this.getDeviceInfo();
    });
  }
  public getDeviceInfo() {
    this.deviceService.getDevice(
      this.currentUser.company_id,
      this.currentUser.branch_id,
      this.deviceId).subscribe((device: DeviceModel) => {
        this.device = device;
        this.modifyInsuranceDetails.controls["insurance_id"].patchValue(this.device?.insurance?.id);
        this.modifyInsuranceDetails.controls["insurance_supplier_id"].patchValue(this.device?.insurance?.insurance_supplier.id);
        this.modifyInsuranceDetails.controls["mobile_provider_id"].patchValue(this.device?.mobile_provider?.id);
      });
  }
  public getInsurances(): void {
    this.deviceService
      .getInsurances()
      .subscribe(response => {
        this.insurances = response.rows;
      });
  }
  public getInsuranceSuppliers(): void {
    this.deviceService
    .getInsuranceProviders()
    .subscribe(response => {
      this.insuranceSuppliers = response.rows;
    });
  }
  public getMobileProviders(): void {
    this.deviceService
      .getMobileProviders()
      .subscribe(response => {
        this.mobileProviders = response.rows;
      });
  }

  confirm() {
    if(this.modifyInsuranceDetails.valid) {
      const data = {
        insurance_id: this.modifyInsuranceDetails.value.insurance_id,
        insurance_supplier_name: this.modifyInsuranceDetails.value.insurance_supplier_name,
        mobile_provider_id: this.modifyInsuranceDetails.value.mobile_provider_id
      };
      this.dialogRef.close(data);
    } else {
      this.toastr.error(this.translate.instant('NOTIFICATIONS.Something went wrong, please try again.'));
    }
  }

}
