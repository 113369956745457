import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RobotActionsModel } from '../../models';
import { PrintService, RobotService } from '../../services';

@Component({
  selector: 'app-action-details',
  templateUrl: './action-details.component.html',
  styleUrls: ['./action-details.component.scss']
})
export class ActionDetailsComponent implements OnInit {
  @Input() actionId;
  showInfo = false;
  expend = false;
  unsanitizeQrCode;
  qrPin;
  public qrCode;
  public actionInfo = new RobotActionsModel();
  constructor(
    private robotService: RobotService,
    private sanitizer: DomSanitizer,
    private printService: PrintService
  ) {}

  ngOnInit(): void {
    this.actionId.subscribe(actionInfo => {
      if (actionInfo) {
        this.getActionInfo(actionInfo.actionId);
        this.qrPin = actionInfo.qrPin;
        this.showInfo = true;
      } else {
        this.showInfo = false;
      }
    });
  }
  closePanel() {
    this.showInfo = false;
  }

  getActionInfo(actionId) {
    this.robotService.getActionById(actionId)
      .subscribe(actionInfo => {
        this.actionInfo = actionInfo;
        this.unsanitizeQrCode = actionInfo?.qrCode;
        this.qrCode = this.sanitizer.bypassSecurityTrustResourceUrl(`${actionInfo?.qrCode}`);
      });
  }
  print() {
    localStorage.setItem('qrCode', this.unsanitizeQrCode);
    const data = {
      pin: this.actionInfo?.pin,
      address: this.actionInfo?.storetec_locker?.address,
      action: this.actionInfo?.type
    }
   this.printService.printDocument('qrCode', data);
}
  setStatusColor(status) {
    switch (status) {
      case 'pending':
        return 'text-blue';
      case 'done':
        return 'text-green';
      case 'cancelled':
        return 'text-red';
    }
  }
}
