import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthenticationService, StyleConfiguratorService } from './shared';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ScriptLoaderService } from './shared/services/script-loader.service';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  
  minutesDisplay = 0;
  secondsDisplay = 0;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  endTime = 15;

  constructor(
    private auth: AuthenticationService,
    private _elementRef: ElementRef,
    private router: Router,
    private confService: StyleConfiguratorService,
    private translate: TranslateService,
    private titleService: Title,
    private renderer: Renderer2, 
    private el: ElementRef,
    private scriptLoadService: ScriptLoaderService,
    private dialogRef: MatDialog
  )
  {}

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  
  reloadTimer() {
    this.auth.notifyUserAction();
  }

  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute('ng-version');
    this.resetTimer();
   


    this.auth.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });

    let res = new URL(window.location.href);
    let user = res.searchParams.getAll('user')[0];
    let company_id = res.searchParams.getAll('sm_company_id')[0];
    let device_id = res.searchParams.getAll('device_id')[0];
    let ext_sm = res.searchParams.getAll('ext_comp')[0];
    this.auth.sm_company_id = company_id;

    //HANDLE SSO 
    let req = new XMLHttpRequest();
    req.open('GET', res.toString(), false);
    req.send();

    const reqRootURL = req.responseURL.split("?")[0];
    
    if (reqRootURL.includes('a1') || this.auth.headers?.ext_sm || (ext_sm && ext_sm !== 'false')) { 
      this.changeTitle('A1 Geräteverwaltung');
      this.changeFavicons('a1');
      this.auth.headers = {
        user: 'a1_user',
        ext_sm: this.auth.headers?.ext_sm || ext_sm
      };
      this.getStyleConfiguration('a1', {ext_sm: this.auth.headers?.ext_sm});
      //load a1 jira widget 
      this.scriptLoadService.loadA1JiraWidget(() => {})
      this.translate.use('de');
    } else {
      //load asset default jira widget 
      this.scriptLoadService.loadAssetJiraWidget(() => {})
    }

    if (user) {
      let loggedUser = JSON.parse(user);
      loggedUser.user.company_id = +company_id;
      localStorage.setItem("current_user", JSON.stringify(loggedUser));
      }
    if (device_id) {
        this.router.navigate([`/devices/${device_id}`])
    }
  }
  

  parseHttpHeaders(httpHeaders) {
    return httpHeaders.split("\n")
     .map(x=>x.split(/: */,2))
     .filter(x=>x[0])
     .reduce((ac, x)=>{ac[x[0]] = x[1];return ac;}, {});
  }


  resetTimer(endTime: number = this.endTime) {
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value => {

    },err => { },
      () => {
        if (!this.auth.headers) {
         
          this.dialogRef.closeAll()
          this.auth.logout();
        }
      }
    )
  }

 getStyleConfiguration(name: string, options?) {
    const cachedConf = this.confService.configuration;
    if (cachedConf) {
      this.confService.setupConfiguration(cachedConf.configuration);
      this.changeFonts();
    } else {
      this.confService.confInit.next(false);
      this.confService.getStyleConfiguration(name)
      .subscribe((configuration) => {
        if (configuration.headers.get('Authorization') && !this.auth.headers?.ext_sm) {
          const a1UserToken =  configuration.headers.get('Authorization').substring(7, configuration.headers.get('Authorization').length);
          let a1User = {user: JSON.parse(atob(a1UserToken.split('.')[1]))}
          localStorage.setItem('current_user', JSON.stringify(a1User)) 
        }
        if (configuration.body) {
          this.confService.configuration = configuration.body;
          this.confService.setupConfiguration(configuration.body.configuration);
          this.changeFonts();
          this.confService.confInit.next(true);
        }
      });
    }
  }

  changeTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  changeFavicons(folder: 'default' | 'a1') {
    const head = this.el.nativeElement.ownerDocument.head;

    const existingFavicons = head.querySelectorAll('link[rel="icon"]');

    existingFavicons.forEach(favicon => {
      this.renderer.removeChild(head, favicon);
    });

    const faviconSizes = ['16x16', '32x32', '96x96'];
    const faviconPath = `assets/favicon/${folder}/favicon-{{size}}.png`;

    faviconSizes.forEach((size) => {
      const newFavicon = this.renderer.createElement('link');
      this.renderer.setAttribute(newFavicon, 'rel', 'icon');
      this.renderer.setAttribute(newFavicon, 'type', 'image/png');
      this.renderer.setAttribute(newFavicon, 'sizes', size);
      this.renderer.setAttribute(newFavicon, 'href', faviconPath.replace('{{size}}', size));
      this.renderer.appendChild(head, newFavicon);
    });
  }

  changeFonts() {
    const fontUrl = getComputedStyle(document.documentElement).getPropertyValue('--font_url').trim();
    const font = getComputedStyle(document.documentElement).getPropertyValue('--font').trim();
    const secFontUrl = getComputedStyle(document.documentElement).getPropertyValue('--secondary_font_url').trim();
    const secFont = getComputedStyle(document.documentElement).getPropertyValue('--secondary_font').trim();
    
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      @font-face {
        font-family: ${font};
        src: ${fontUrl} ;
      }
      @font-face {
        font-family: ${secFont};
        src: ${secFontUrl} ;
      }
    `;

    document.head.appendChild(styleTag);
  }

  ngOnDestroy(): void {
    localStorage.clear();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
