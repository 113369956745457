import { Routes } from "@angular/router";
import { AuthGuard } from "./shared";

import { AdminComponent } from "./admin/admin.component";
import { AuthenticationComponent } from "./authentication/authentication.component";
import { ErrorPageComponent } from "./shared/components/error-page/error-page.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";


export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Dashboard'
        }
      },
      {
        path: "branch",
        loadChildren: () => import('./admin/branches/branches.module').then(m => m.BranchesModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Branch'
        }
      },
      {
        path: "users",
        loadChildren: () => import('./admin/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Users'
        }
      },
      {
        path: "employees",
        loadChildren: () => import('./admin/employees/employees.module').then(m => m.EmployeesModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Employees'
        }
      },
      {
        path: "devices",
        loadChildren: () => import('./admin/devices/devices.module').then(m => m.DevicesModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Devices'
        }
      },
      {
        path: "archived-devices",
        loadChildren: () => import('./admin/archive/archive.module').then(m => m.ArchiveModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Archived Devices'
        }
      },
      {
        path: "EOL",
        loadChildren: () => import('./admin/eol/eol.module').then(m => m.EolModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'EOL'
        }
      },
      {
        path: "robot",
        loadChildren: () => import('./admin/robot/robot.module').then(m => m.RobotModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Robot'
        }
      },
      {
        path: "orders",
        loadChildren: () => import('./admin/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Orders'
        },
      },
        {
        path: "break-and-fix",
        loadChildren: () => import('./admin/break-and-fix/break-and-fix.module').then(m => m.BreakAndFixModule),
        canActivate: [AuthGuard],
        data: {
          breadscrumb: 'Break & Fix'
        }
      }
    ],
  },
  {
      path: "print",
      loadChildren: () => import('./shared/print-layout/print-layout.module').then(m => m.PrintLayoutModule),
  },
  {
    path: "",
    component: AuthenticationComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      },
    ],
  },
  {
    path: "lissa-lib",
    children: [
      {
        path: "",
        loadChildren: () => import('./shared/lissa-library/lissa-library.module').then (m => m.LissaLibraryModule)}
    ]
  },
  {
    path: "not-found",
    component: NotFoundComponent
  },
  {
    path: "**",
    component: ErrorPageComponent
  },
];
