import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AvatarInitialsPipe, DeviceActivityPipe, FullNamePipe } from "./";
import { AmountFormtedPipe } from './amount.pipe';
import { DateFormat } from './dateFormat.pipe';
import { SortByPipe } from './sortBy.pipe';
import { EolDate } from './eol-correct-date.pipe';
import { TruncatePipe } from "./truncate.pipe";
import { UnresolvedActionPipe } from "./unresolved-action.pipe";
import { HasRobotAction } from "./robot-action.pipe";
import { LastCompletedStepInfoPipe } from './last-completed-step-info.pipe';

@NgModule({
  declarations: [
    AvatarInitialsPipe, 
    FullNamePipe, 
    AmountFormtedPipe, 
    DateFormat, 
    SortByPipe, 
    EolDate, 
    TruncatePipe,
    DeviceActivityPipe,
    UnresolvedActionPipe,
    HasRobotAction,
    LastCompletedStepInfoPipe
  ],
  imports: [CommonModule],
  exports: [
    AvatarInitialsPipe, 
    FullNamePipe, 
    AmountFormtedPipe, 
    DateFormat, 
    SortByPipe, 
    EolDate, 
    TruncatePipe,
    DeviceActivityPipe,
    UnresolvedActionPipe,
    HasRobotAction,
    LastCompletedStepInfoPipe
  ]
})
export class MainPipe {}
