import { Component, EventEmitter, Input,OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BranchModel, BranchService, UserModel, UserService } from '../..';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isFormDirtyAndChanged } from '../../helpers/form-dirty-validation';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user-details',
  templateUrl: './edit-user-details.component.html',
  styleUrls: ['./edit-user-details.component.scss']
})
export class EditUserDetailsComponent implements OnInit {
  @Input() userId: EventEmitter<any>;
  @Output() userCreated = new EventEmitter<any>();
  showInfo = false;
  errorMessage = null;
  manager: boolean = false;
  public branches: BranchModel[] = [];
  public selectedBranches: BranchModel[] = [];
  public allBranchesChecked:any [] = [];
  userForm: FormGroup;
  public filteredBranches: Subscription;
  public user = new UserModel();
  public initialFormData: any;
  public submitable: boolean = false;
  
  

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private branchService: BranchService,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService
  ) { 
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.buildUserForm();
    this.userId.subscribe(user => {
      this.showInfo = user ? true : false;
      this.user = user;
      this.manager = user.user_roles.find(usr => usr.role === 'manager');
      this.getUser(user?.id);
      this.getBranches();     
    })
    this.userForm.valueChanges
    .pipe( 
      debounceTime(500)
    )
    .subscribe(() => {
      this.submitable = isFormDirtyAndChanged(this.userForm, this.initialFormData) && this.userForm.valid
    })
  }

  buildUserForm() {
    this.userForm = this.fb.group({
      first_name: [],
      last_name: [],
      email: [null, [Validators.required, Validators.email]],
      branch_id: [],
      corporate_id: [],
      cost_center: [],
      department: [],
      info: [],
      phone_number: [null, Validators.pattern('[- +()0-9]+')],
      company_id: [null],
      role: ["manager"],
      permissions: this.fb.group({
        read_only: []
      })
    });
  }

  public getUser(userId): void {
    this.errorMessage = null;
    this.userService.getUserById(userId).subscribe(
      (user: UserModel) => {
        this.user = user;
        //TODO check if loged user is manager 
        // if(user.user_branches) {
        //   user.user_branches.map(branch => {
        //     this.branches.push(branch.branch);
        //   })
        // }
      },
      error => {
        this.errorMessage =
          error.error != null
            ? error.error.message
            : "Something went wrong, please try again.";
      }
    );
  }

  public getBranches(): void {
    this.branchService
      .getBranches(this.user.company_id, '1')
      .subscribe(response => {
        this.branches = response.rows;

        let userBranches: any[] = this.user?.user_branches.map(branches => branches?.branch.id);
        userBranches = this.branches.filter(branches => userBranches.includes(branches?.id));

        this.userForm.patchValue({...this.user, branch_id: userBranches, permissions: {read_only: this.user.read_only} });
        this.initialFormData = {...this.userForm.value, branch_id: [...userBranches]};
      });
  }
  public allBranchesSelected(checked) {
    if(checked) {
      if(this.branches.length > 0) {;
        let ids = this.branches.map(id => id.id);
        this.userForm.patchValue({
          branch_id: this.branches
        })
        this.allBranchesChecked = ids;
      } 
    } else {
      this.getBranches();
      this.allBranchesChecked = [];
      this.userForm.patchValue({
        branch_id: null
      })
      
    }
   
  }
  public deleteItem(event, index) {
    if(this.userForm.value.branch_id.length > 1){

      this.userForm.value.branch_id.splice(index, 1);
      let branches = this.userForm.value.branch_id;
      this.userForm.patchValue({
        branch_id: branches
      })
    }
    else{
      this.toastr.error(this.translate.instant("NOTIFICATIONS.Manager has to have at least one branch"));
    }
  }

  public closeForm(){
    this.showInfo = false;
    this.userForm.reset()
  }

  saveUser() {
    if (this.userForm.valid) {

      if(this.branches.length > 0) {
        if (this.userForm.value.branch_id) {
          let _id = this.userForm.value.branch_id.map(id => id.id);
          this.userForm.controls['branch_id'].patchValue(_id);
        } else {
          let userBranches = this.user.user_branches.map(branch => branch?.id);
          this.userForm.patchValue({branch_id: userBranches});
        }
        
      }

     
      this.userService.updateManager(this.userForm.value, this.user.email)
        .subscribe(
            () => {
              this.userForm.reset();
              this.showInfo = false;
              this.userCreated.emit(true);
              this.toastr.success(this.translate.instant(`NOTIFICATIONS.User successfully updated`));
              this.router.navigate(["/users/list"])
            },
            error => {
              this.errorMessage =
                error.error != null
                  ? error.error.message
                  : this.translate.instant('NOTIFICATIONS.Something went wrong, please try again.');
                    this.toastr.error(this.errorMessage);
            }
          );
      }
  }
}
