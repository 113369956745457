import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BranchModel } from '../../models';
import { BranchService } from '../../services';
import { isFormDirtyAndChanged } from '../../helpers/form-dirty-validation';

@Component({
  selector: 'app-change-branch-modal',
  templateUrl: './change-branch-modal.component.html',
  styleUrls: ['./change-branch-modal.component.scss']
})
export class ChangeBranchModalComponent implements OnInit {
  public branchesList: BranchModel[] = [];
  public updateBranchForm: FormGroup;
  public company_id: number;
  public customText: string;
  public comment: any | null;
  public show = false;
  public branch = new BranchModel();
  public initialFormData: any;
  public submitable: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangeBranchModalComponent>,
    private branchService: BranchService,
    private translate: TranslateService
  ) {
    this.company_id = data.company_id;
    this.customText = data != null ? data.text : null;
    this.branch = data?.branch;
    this.initialFormData = {branch_id: data?.branch?.id}
   }

  ngOnInit() {
    this.updateBranchForm = this.fb.group({
      branch_id: [null, Validators.required],
      comment: [],
    });
    this.getBranches();
    this.updateBranchForm.valueChanges
    .subscribe(() => {
      this.submitable = isFormDirtyAndChanged(this.updateBranchForm, this.initialFormData)
    })
  }

  public getBranches() {
    this.branchService.getBranches(this.company_id, '1')
      .subscribe(branches => {
        this.branchesList = branches.rows.filter(branch => branch?.id !== this.branch?.id);
      });
  }
  getComment(comment) {
    this.comment = comment;
    this.updateBranchForm.patchValue({comment});
  }
  confirm() {
    this.dialogRef.close(this.updateBranchForm.value);
  }

}
