import { Component, OnInit } from '@angular/core';
import { PaginationService } from '../shared';
import { Subscription } from 'rxjs';
import { StyleConfiguratorService } from '../shared';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
  public confInit: boolean;
  confSub$: Subscription;

  constructor(private confService: StyleConfiguratorService,  private paginationService: PaginationService) { 
  }

  ngOnInit() { 
   this.confSub$ = this.confService.confInit.subscribe(init => this.confInit = init);
  }

  ngOnDestroy(): void {
   this.confSub$.unsubscribe();
   this.paginationService.clearSettings();
  }
}
