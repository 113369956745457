
<div class="pb-4 m-1">
  <mat-dialog-content class="flex-column">
   <div class="d-flex justify-content-between mb-2 align-items-center">
            <h2 class="center-title mb-0" style="color: #86909E;">{{'MODALS.Assign Device Confirmation' | translate}}</h2>
            <span class="material-icons close-modal" mat-dialog-close>
                <img src="../../../../assets/icons/cancel_black_new.svg" />
            </span>
    </div>
    <div class="space-line__two mb-3"></div>
    <div class="flex-column">
        <h2 class="center">{{'MODALS.Device successfully assigned!' | translate}}</h2>
        <p>{{'MODALS.Assign to Employee' | translate}} {{employeeInfo?.employee_name | fullname}}</p>
        <p>{{'MODALS.Device' | translate}} {{device?.device_variant ? 
          device?.device_variant.description : 
         device?.denormalized_device_variant?.name
         }}</p>
    </div>
    <div class="email-input" *ngIf="emailNotFound">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Mail to"/>
        </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="center justify-content-center">
      <button mat-button mat-dialog-close class="cancel-btn">{{'MODALS.Close' | translate}}</button>
      <button mat-button (click)="confirm()" class="blue-button">{{'MODALS.Print confirmation' | translate}}</button>
      <button mat-button (click)="sendToEmail()" class="blue-button" *ngIf="internalUser">{{'MODALS.Send to email' | translate}}</button>
  </mat-dialog-actions>
</div>
