import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";


@Injectable()
export class UnifiedVariantsService {
  private assetUrl = `${environment.assetUrl}/asset`;
  private adminUrl = `${environment.assetUrl}/admin`;
  constructor(private http: HttpClient) { }

  searchUnifiedVariants(perPage: number, itemsLimit: number, filterObject: any): Observable<any> {
    let query = `?page=${perPage}&limit=${itemsLimit}`;
      
    let params = new HttpParams();
    Object.keys(filterObject).forEach(k => {
      if (filterObject[k] && typeof filterObject[k] != 'object' && k !== 'client_category_id' && k !== 'manufacturer_code') {
          params = params.set(k, filterObject[k]);
      }
    })
    return this.http.get(`${this.adminUrl}/device/variant/unified-variant${query}`, {params})
      .pipe(catchError((error: any) => throwError(error)));
  }

  searchVariants(searchStr: string): Observable<any> {
    return this.http.get(`${this.adminUrl}/device/variant/general-search?searchStr=${searchStr}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

}