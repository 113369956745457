import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, startWith, tap } from 'rxjs/operators';
import { AreYouSureModalComponent } from '../../modal-dialogs/are-you-sure-modal/are-you-sure-modal.component';
import {CategoryModel} from '../../models/category.model';
import { BranchService, UnifiedVariantsService } from '../../services';
import { ClientCategoryService } from '../../services/client-category.service'; 

@Component({
  selector: 'app-create-branch-categories',
  templateUrl: './create-branch-categories.component.html',
  styleUrls: ['./create-branch-categories.component.scss']
})
export class CreateBranchCategoriesComponent implements OnInit {
   
  public categoriesForm:FormGroup;
  public categoryVariantsForm:FormGroup;
  public selectedCategories: any[] = [];
  public selectedVariants: string[] = [];
  public inputIndex: number;
  public branchCategories: CategoryModel[] = [];
  public fetchedVariants: [] = [];
  public unifiedVariantsSubscription: Subscription;
  public branchInfo: {
    branch_id: number, 
    company_id: number, 
    category_id: number,
    devices: number
  };

  showInfo: boolean = false;
  isEdit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private branchService: BranchService,
    private unifiedService: UnifiedVariantsService,
    private clientCatService: ClientCategoryService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.branchInfo = data;
   }

  ngOnInit(): void {
    this.buildCategoriesForm();
    this.buildCatgoryVariantsForm();
    this.getBranchCategories();
    this.initFilters();
  } 

  public buildCategoriesForm(): void {
    this.categoriesForm = this.fb.group({
      branch_id: [],
      categories:[null, [Validators.required]]
    })
  }

  public buildCatgoryVariantsForm(): void {
    this.categoryVariantsForm = this.fb.group({
      client_category_id: [],
      name:[{value: '', disabled: !this.branchInfo.devices}],
      manufacturer_code: []
    })
  }

 public addCategory(event, catName) {
   event.preventDefault(); 
   if (catName) {
    this.selectedCategories.push({name: catName});
    this.createCategories();
   }
  }

  public removeCategory(catID, catArrayItemID) {   
    
    const dialogRef = this.dialog.open(AreYouSureModalComponent);
   
    dialogRef.afterClosed().subscribe(res =>  {
        if (res && catID) {
          this.clientCatService.deleteCategory(this.branchInfo, catID)
          .subscribe(res => {
            this.toastr.success(this.translate.instant('NOTIFICATIONS.Category deleted'));
          })
          this.selectedCategories.splice(catArrayItemID, 1);
        }
    })
    
  }

  public getBranchCategories() {
    this.branchService.getBranchCategories(this.branchInfo.company_id, this.branchInfo.branch_id)
      .subscribe(categories => {
        this.selectedCategories = categories;
        this.branchCategories = categories;
      });
  }

  public createCategories(){
   const payload = {
      branch_id: this.branchInfo.branch_id,
      categories: this.selectedCategories.filter(categories => !categories.id)
    } 
    
    if (this.selectedCategories.length > 0) {
      this.clientCatService.createBranchCategories(this.branchInfo.company_id, this.branchInfo.branch_id, payload)
        .subscribe(res => this.getBranchCategories());
        this.toastr.success(this.translate.instant('NOTIFICATIONS.Category created'));
    }
  }

  public updateCategory(category_id: number, value) {
    if (category_id) {
      const newCategoryName = {name: value};
      this.clientCatService.updateClientCatName(this.branchInfo, category_id, newCategoryName)
        .subscribe(res => {
          this.getBranchCategories();
          this.toastr.success(this.translate.instant('NOTIFICATIONS.Category updated'))
          this.isEdit = false;
          this.inputIndex = null;
        });
    } else {
      this.isEdit = false;
      this.selectedCategories[this.inputIndex].name = value;
    }
  }

  public allowEdit(catItemIndex) {
    this.inputIndex = catItemIndex;
    if (this.inputIndex == catItemIndex) {
        this.isEdit = true;
    } 
  }

  public initFilters() {
    this.unifiedVariantsSubscription = this.categoryVariantsForm.controls.name.valueChanges
    .pipe(startWith(null))
    .pipe(debounceTime(500))
    .pipe(
      tap(val => {
        if (val && typeof val != "object") {
          this.unifiedService.searchUnifiedVariants(1, 10, {...this.categoryVariantsForm.value, branch_id: this.branchInfo?.branch_id})
          .subscribe(variants => {
            variants.rows.map((variant: any) => {
              if (this.selectedVariants.includes(variant?.manufacturer_code)) {
                variant.device_variant ? 
                  variant.device_variant.checked = true : 
                  variant.denormalized_device_variant.checked = true;
              }
            })
            this.fetchedVariants = variants.rows.map(variant => variant?.device_variant ? 
              variant?.device_variant : 
              variant?.denormalized_device_variant);
          })
        } else {
          this.fetchedVariants = [];
        }
      }))
    .subscribe();
  }

 public onVariantSelect(event, variant) {
    if (event.checked) {
      this.selectedVariants.push(variant.manufacturer_code);
    } else { 
      this.selectedVariants.splice(this.selectedVariants.indexOf(variant.manufacturer_code), 1);
    }
  }

 public addVariantsToCategory(){
    if (this.selectedVariants.length > 0 && this.categoryVariantsForm.value.client_category_id) {
      this.categoryVariantsForm.patchValue({manufacturer_code: this.selectedVariants})
      this.clientCatService.addVariantsToCategory(this.branchInfo, this.categoryVariantsForm.value)
        .subscribe(res => {
          this.toastr.success(this.translate.instant('NOTIFICATIONS.Variant added'));
          this.categoryVariantsForm.reset();
        });
       
    }
  }
  
getCategoryVariants(category_id) {
  this.selectedVariants = [];
  this.branchInfo.category_id = category_id;
  this.clientCatService.getCategoryVariants(this.branchInfo)
    .subscribe(res => {
      this.fetchedVariants = res.map(variants => 
        {     
          this.selectedVariants.push(variants.unified_variant?.manufacturer_code);
           return {
            checked: true,
            cat_id : variants.client_category_id, 
            ...variants.unified_variant?.denormalized_device_variant ?
            variants.unified_variant?.denormalized_device_variant : 
            variants.unified_variant?.device_variant }
        }) 
    });}

    
}
