<div class="list-group action-needed-box action-needed-box-1  p-3 action-needed-container">
    <div class="row">
        <div class="col-2">
        <span class="material-icons notification-icons action d-flex justify-content-center">
              notifications_active
        </span>
        </div>
         <div class="col-10">
        <h3 class="section-heading mb-3">
              {{'BREADSCRAMBS.Action needed?' | translate}}
        </h3>
        <p class="pb-2 section-text" *ngIf="actionNeededDevices; else noAction">{{'BREADSCRAMBS.For' | translate}}
              {{actionNeededDevices ? actionNeededDevices : 0}}
              {{actionNeededDevices > 1 ? ('BREADSCRAMBS.devices' | translate) : ('BREADSCRAMBS.device' | translate)}}
              {{'BREADSCRAMBS.they could be action needed from your side.' | translate}}</p>
        <ng-template #noAction>
            <p>{{'BREADSCRAMBS.Action text' | translate}}</p>
        </ng-template>
        <a class="btn btn-primary home_screen_button" 
              [class.btn-disabled]="currentUser?.read_only"
              [routerLink]="['/devices/list']"
              [queryParams]="{ action_needed: true}">{{'DASHBOARD.Take action' | translate | titlecase}} <img src="../../../assets/icons/strelica.png" /> </a>
        </div>
    </div>
</div>

