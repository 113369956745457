import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
@Injectable()
export class NotificationService {
    private assetUrl = `${environment.assetUrl}/asset`;

    constructor(
        private http: HttpClient
    ) { }

    sendToMailAssigment(assignmentData): Observable<any> {
        return this.http
        .post(`${this.assetUrl}/company/${assignmentData?.company_id}/device/${assignmentData?.device_id}/assign-email-notification`, assignmentData)
        .pipe(catchError((error: any) => throwError(error)));
    }
    
}