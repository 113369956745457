import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() currentStep: number = 0;
  @Input() totalSteps: number = 1;

  constructor() { }


  getStepsArray(): number[] {
    return Array(this.totalSteps).fill(0).map((x, i) => i + 1);
  }

}
