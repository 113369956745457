export class CompanyModel {
  id: number;
  name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zip: string;
  logo: string;
  invoice_email: string;
  created_at: string;
  updated_at: string;
  print_swap: number;
  storetec_locker_companies: [];
  country_id: number;
}
