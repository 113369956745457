<form class="form ng-pristine example-form" [formGroup]="categoriesForm">
  <div class="pb-4">
    <mat-dialog-content class="flex-column">
      <div class="d-flex justify-content-between mb-2 align-items-center">
        <h2 class="center-title mb-0" style="color: #86909E;">{{'CATEGORYFORM.Create' | translate}}</h2>
        <span class="material-icons close-modal" mat-dialog-close>
          <img src="../../../../assets/icons/cancel_black_new.svg" />
        </span>
      </div>
      <div class="space-line__two mb-3"></div>  
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="branch-group d-flex">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>{{'CATEGORYFORM.Category' | translate}}</mat-label>
                  <input matInput placeholder="{{'CATEGORYFORM.Category name' | translate }}" formControlName="categories" #catName/>
                </mat-form-field>
                <button class="btn btn-primary mr-1 mt-2 align-self-baseline ml-2 d-flex justify-content-center" 
                [disabled]="!categoriesForm.valid" (click)="addCategory($event, catName.value)">{{'CATEGORYFORM.Add' | translate}}</button>
              </div>
              <div class="category">
                <div *ngFor="let category of selectedCategories; let catID = index;" class="app-badge-pill checked">
                  <input matInput type="text" [readonly]="catID !== inputIndex" [value]="category?.name" #name class="category-item"/>        
                  <a class="delete-x-button ml-2 d-flex">
                    <mat-icon class="material-icons-outlined icon" *ngIf="isEdit && catID == inputIndex" (click)="updateCategory(category?.id, name.value, event)">done</mat-icon>
                    <mat-icon class="material-icons-outlined icon icon-edit" (click)="allowEdit(catID)" *ngIf="!isEdit">edit</mat-icon>          
                  </a>
                  <a class="delete-x-button ml-2" (click)="removeCategory(category?.id, catID)">
                    <img src=" ../../../../../../assets/icons/x-button-blue.png">
                  </a>
                </div>
              </div>
            </div>
          </div>
    
    </mat-dialog-content>
  </div>
</form>


<form class="form pb-4 m-1 example-form" [formGroup]="categoryVariantsForm">
  <mat-dialog-content class="flex-column">
    <div class="d-flex justify-content-between mb-2 align-items-center">
      <h2 class="center-title mb-0" style="color: #86909E;">{{'CATEGORYFORM.Create Category Variants' | translate}}</h2>
    </div>
    <div class="space-line__two mb-3"></div>
   
    <div class="branch-group">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{'CATEGORYFORM.Select' | translate}}</mat-label>
        <mat-select formControlName="client_category_id" (selectionChange)="getCategoryVariants($event.value)" required>
          <mat-option *ngFor="let category of branchCategories" [value]="category?.id">
            {{category?.name}}
           
          </mat-option>
        </mat-select>
        <mat-error *ngIf="
        categoriesForm
          .get('categories')
          .hasError('required')
      ">
          {{ "Please insert Category"}}
        </mat-error>
        
      </mat-form-field>
       <div class="flex-wrap" role="group">
        <p *ngIf="!branchInfo.devices">{{'CATEGORYFORM.There is no' | translate}}</p>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{'CATEGORYFORM.Search' | translate}}</mat-label>
            <input type="text" matInput   autocomplete="off" formControlName="name">
        </mat-form-field>
      </div>
      <div class="d-flex flex-column">
        <mat-checkbox *ngFor="let variant of fetchedVariants"
          [checked]="variant?.checked"
         (change)="onVariantSelect($event, variant)">{{variant?.description || variant?.name}}</mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button  mat-button class="blue-button mr-1 mt-2" (click)="addVariantsToCategory()">{{'CATEGORYFORM.Add Variant' | translate}}</button>
  </mat-dialog-actions>
</form>