import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-device-sc',
  templateUrl: './cancel-device-sc.component.html',
  styleUrls: ['./cancel-device-sc.component.scss']
})
export class CancelDeviceScComponent implements OnInit {
  readonly infoData;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CancelDeviceScComponent>,
  ) { 
    this.infoData = data;
  }

  ngOnInit(): void {
  }
  confirm(){
    this.dialogRef.close(true);
  }
}
