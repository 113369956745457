import { Injectable } from "@angular/core";

@Injectable()
export class Enums {
  deviceCapacity = ["8 GB", "16 GB", "32 GB", "64 GB", "128 GB", "256 GB"];

  contractDurations = ["12", "24", "36", "48", "60"];

  deviceStatuses = ["active", "pool"];
  daysOptions = [
    { name: '30 days', value: '30', isChecked: false},
    { name: '60 days', value: '60', isChecked: false},
    { name: '90 days', value: '90', isChecked: false}
  ];
  deviceStatements = [
    { id: 'radio-one', name: 'Erase', value: 'erase', isChecked: false, disabled: false},
    { id: 'radio-two', name: 'Roll-back', value: 'roll-back', isChecked: false, disabled: false},
    { id: 'radio-three',  name: 'Non Return', value: 'non-return', isChecked: false, disabled: false}
  ];

  stepsGrayIcons = [
    {icon: 'description', text: 'Service case created'},
    {icon: 'play_arrow', text: 'Service started'},
    {icon: 'analytics', text: 'Troubleshooting'},
    {icon: 'report_problem', text: 'Action needed'},
    {icon: 'local_shipping',  text: 'Procuring Parts'},
    {icon: 'settings_suggest', text: 'Repaired'},
    {icon: 'task', text: 'Done'},
  ];
  stepsColoredIcons = [
    {icon: '../assets/icons/service-steps-icons/colored/1.svg', text: 'Service case created'},
    {icon: '../assets/icons/service-steps-icons/colored/2.svg', text: 'Service started'},
    {icon: '../assets/icons/service-steps-icons/colored/3.svg', text: 'Troubleshooting'},
    {icon: '../assets/icons/service-steps-icons/colored/7.svg', text: 'Action needed'},
    {icon: '../assets/icons/service-steps-icons/colored/4.svg', text: 'Procuring Parts'},
    {icon: '../assets/icons/service-steps-icons/colored/5.svg', text: 'Repaired'},
    {icon: '../assets/icons/service-steps-icons/colored/6.svg', text: 'Done'},
  ];
  archiveReasons = [
    'Returned to supplier', 
    'Irreparable',
    'Lost',
    'Stolen',
    'Employee left the company',
    'Buyout',
    'Other'
  ]
  eolPlaceholderMessage = `
  Device expiring in {{days}},

  Dear Admin,

  It's Time to say goodbye
  Your device name with ID number {{identifier}} is expiring on date.
  Please discuss further steps with the device manager in your organisation.

  Best regards, LISSA.`;


  actionConfirmationText = {
    fill: 'Device inserted successfully!',
    return: 'Device returned successfully!',
    empty: 'Robot emptied successfully!'
  }
}
