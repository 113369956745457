import { DeviceModel } from ".";
import { BranchModel } from "./branch.model";
import { CompanyModel } from "./company.model";
import { TranslateService } from '@ngx-translate/core';

export class RobotModel {
    id: number;
    haai_id: number;
    uuid: number;
    address: string;
    serial_number: string;
    pin: {
        lat: string,
        lon: string
    };
    capacity: number;
    zip: string;
    city: string;
    country: string;
    storetec_locker_companies: CompanyModel;
    storetec_locker_branches: BranchModel;
    storetec_device_lockers: DeviceModel;
    created_at: string;
    updated_at: string;
    max_space: number;
    used_space: number;
}

export class RobotNotificationModel {
    title: string;
    heading: string;
    type: string;
    emails: string[];
    template: string;
 }
export const ROBOT_NOTIFICATIONS: RobotNotificationModel[] = [
    {title: 'ROBOTNOTIFICATIONS.SWAP device', heading: "ROBOTNOTIFICATIONS.Device SWAP notifications", type: 'swap', emails:[], template: null},
    {title: 'ROBOTNOTIFICATIONS.Return device', heading: "ROBOTNOTIFICATIONS.Device RETURN notifications", type: 'return', emails: [], template: null},
    {title: 'ROBOTNOTIFICATIONS.Who should receive these notifications', heading: "ROBOTNOTIFICATIONS.Device is more then 120 days without any action", type: 'exp_120', emails: [], template: null},
]