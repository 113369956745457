<div>
    <form class="wizard-big wizard clearfix mx-3" role="form">
        <mat-dialog-content class="flex-column p-2">
            <div class="col-12 col-md-12">
              <div class="change-status-border">
                <h2 class="left robot-modal-title m-0" *ngIf="!swap; else swapText">{{customText}}</h2>
                <ng-template #swapText>
                    <h2 class="left robot-modal-title m-0">{{'MODALS.SWAP Device Confirmation' | translate}} </h2>
                </ng-template>
                <div class="divider"></div>
              </div>
              <div class="row">
                <div class="col-12">
                    <p class="left robot-modal-description" *ngIf="!customText">You have successfully created Robot request.</p>
                    <!-- <ng-template #text>
                        {{customText}}
                    </ng-template> -->
                    <div *ngIf="assign">
                      <p class="left robot-modal-description">{{'MODALS.You have successfully' | translate}}.</p>
                      <div> 
                        <p class="robot-modal-description">Assigning:</p>
                        <p class="robot-modal__text">Device: <span class="robot-modal-description">{{device?.device?.device_variant ? 
                          device?.device?.device_variant.description : 
                         device?.device?.denormalized_device_variant?.name
                         }}</span></p>
                        <p class="robot-modal-description">to</p>
                        <p class="robot-modal__text">Employee: 
                          <span class="robot-modal-description">
                            {{employee?.first_name}} {{employee?.last_name}}
                          </span>
                        </p>
                        <p class="robot-modal__text">Active from: </p>
                        <p class="robot-modal__text" *ngIf="unsanitizeQrCode">QR and PIN codeis sent to mail: <span class="robot-modal-description">{{email}}</span></p>
                        <p class="robot-modal__text" *ngIf="unsanitizeQrCode">Please check email for further information.  </p>
                        <p class="robot-modal__text" *ngIf="unsanitizeQrCode">Use either PIN or QR code. </p>
                        
                        <div class="divider"></div>
                          <p class="robot-modal__text" *ngIf="unsanitizeQrCode"> Robot's location:</p>
                          <div class="location"> 
                            <img src="/assets/icons/map-pin.png" alt=""> 
                            {{address || device?.device?.storetec_device_locker?.storetec_locker?.address}}
                          </div>
                       </div>
                      </div>
                    <div *ngIf="!swap; else swapInfo">
                      <!-- <p class="robot-modal__text">QR code is sent to mail: <span class="robot-modal-description">{{actionInfo?.email}}</span></p> 
                      <p class="robot-modal__text">Please check email for QR code and further information. </p> -->
                    </div>
                   <ng-template #swapInfo>
                    <div>
                     <p class="robot-modal-description">{{'SWAPDEVICECONFIRMATION.Swapping' | translate}}:</p>
                     <p class="robot-modal__text">{{'SWAPDEVICECONFIRMATION.Current device' | translate}}: <span class="robot-modal-description">{{device?.device?.device_variant ? 
                       device?.device?.device_variant.description : 
                      device?.device?.denormalized_device_variant?.name
                      }}</span></p>
                    <p class="robot-modal__text"> {{'SWAPDEVICECONFIRMATION.SN' | translate}}: 
                        <span class="robot-modal-description">{{device?.device?.serial_number || 'N/A'}}</span>
                    </p>
                    <p class="robot-modal__text"> {{'SWAPDEVICECONFIRMATION.IMEI' | translate}}: 
                        <span class="robot-modal-description">{{device?.device?.imei || 'N/A'}}</span>
                    </p>
                    <p class="robot-modal__text"> {{'SWAPDEVICECONFIRMATION.Phone number' | translate}}: 
                      <span class="robot-modal-description">{{device?.device?.phone_number || 'N/A'}}</span>
                    </p>
                    <div class="divider"></div>
                    <!-- <p class="robot-modal-description">with</p> -->
                     <p class="robot-modal__text">{{'SWAPDEVICECONFIRMATION.New Device' | translate}}: 
                       <span class="robot-modal-description">
                        {{device?.pool_device_id?.device_variant ? 
                          device?.pool_device_id?.device_variant.description : 
                        device?.pool_device_id?.denormalized_device_variant?.name
                        }}
                      </span>
                    </p>

                    <p class="robot-modal__text"> {{'SWAPDEVICECONFIRMATION.SN' | translate}}: 
                        <span class="robot-modal-description">{{device?.pool_device_id?.serial_number || 'N/A'}}</span>
                    </p>
                    <p class="robot-modal__text"> {{'SWAPDEVICECONFIRMATION.IMEI' | translate}}: 
                        <span class="robot-modal-description">{{device?.pool_device_id?.imei || 'N/A'}}</span>
                    </p>
                    <p class="robot-modal__text"> {{'SWAPDEVICECONFIRMATION.Phone number' | translate}}: 
                      <span class="robot-modal-description">{{device?.pool_device_id?.phone_number || 'N/A'}}</span>
                    </p>

                    <div class="divider"></div>

                     <p class="robot-modal__text" *ngIf="unsanitizeQrCode">QR code is sent to mail: <span class="robot-modal-description">{{device?.email}}</span></p>
                     <p class="robot-modal__text" *ngIf="unsanitizeQrCode">Please check email for QR code and further information. </p>
                     <p class="robot-modal__text" *ngIf="device?.device_lifecycle?.date_start">Lifecycle: <span class="robot-modal-description">{{device?.device_lifecycle?.date_start | dateFormat : 'YYYY-MM-DD'}}</span></p>
                     <p class="robot-modal__text">{{'SWAPDEVICECONFIRMATION.Additional note' | translate}}: {{device?.additional_note}} </p>
                    <div class="divider"></div>
                      <p class="robot-modal__text" *ngIf="unsanitizeQrCode"> Robot's location:</p>
                      <div class="location" *ngIf="address"> 
                        <img src="/assets/icons/map-pin.png" alt=""> 
                        {{address || device?.device?.storetec_device_locker?.storetec_locker?.address}}
                      </div>
                    </div>
                    <mat-radio-group class="d-flex flex-column" (change)="keep_sim = $event.value">
                      <mat-radio-button [value]="true"  *ngIf="device?.device?.phone_number">{{'SWAPDEVICECONFIRMATION.I want to keep' | translate}}</mat-radio-button>
                      <mat-radio-button [value]="false" *ngIf="device?.pool_device_id?.phone_number">I want to get a new SIM card information.</mat-radio-button>
                    </mat-radio-group>
                   </ng-template>
                </div>
                <!-- <div class="col-6 d-flex" *ngIf="unsanitizeQrCode">
                  <div class="qrcode-img">
                    <img [src]="qrCode" alt="">
                  </div>
                </div> -->
              </div>

            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-content-end my-2">
            <button class="btn btn-cancel mr-3" (click)="printQrCode()" *ngIf="unsanitizeQrCode">
              {{'DEVICECHANGESTATUS.Print' | translate }}</button>
            <button type="submit" class="btn btn-primary" (click)="confirm()">{{'SWAPDEVICECONFIRMATION.DONE' | translate | titlecase}}</button>
        </mat-dialog-actions>
    </form>
</div>


