<mat-dialog-content class="flex-column">

  <div class="nav">
    <h1>Archive Device</h1> <span mat-button mat-dialog-close class="x-icon"><img
        src="../../../../assets/icons/x-button.png"></span>
  </div>
  <div class="space-line"></div>
  <form [formGroup]="archiveForm">
    <h2 class="title mt-2">{{'MODALS.Why do you' | translate}}</h2>
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{'MODALS.Select' | translate}}</mat-label>
      <mat-select formControlName="reason">
        <mat-option *ngFor="let reason of reasons | async" [value]="reason">{{'ARCHIVINGPOPUPREASON.' + (reason | titlecase) | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="archiveForm.value.reason">
      <span class="archive-device__section-title mb-2">
        Upload file
      </span>
      <div class="dropzone-archive-file col-12 my-3" [style.borderColor]="['#4274F7']" ngx-dropzone [accept]="'.pdf'"
        (change)="onFileUpload($event)">
        <ngx-dropzone-label>
          <div>
            <img src="../../../../assets/icons/dragANDdrop.svg" />
            <h2 class="drag-drop-text">Drag&Drop or <span style="color: #5757F5;">Browse </span> an file
            </h2>
            <h2 class="drag-drop-text">(.pdf)*</h2>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of uploadedFile" [file]="f" [removable]="true"
          (removed)="onRemove(f, 'cover')" class="file-preview">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </div>
    </div>


    <mat-form-field class="full-width" appearance="outline">
      <textarea matInput rows="4" formControlName="note" placeholder="{{'MODALS.Write' | translate}}"></textarea>
    </mat-form-field>
  </form>
  <div class="remove-class">
    <h2 class="title">{{'MODALS.Do you' | translate}}</h2>
    <div class="first mb-2">
      <h2>{{'MODALS.Archive device' | translate}}: <span>{{deviceName}} ({{deviceSerial}}) </span></h2>
      <h2>{{'MODALS.Employee' | translate}}: <span>{{employee}}</span></h2>
    </div>
  </div>
  <div class="space-line"></div>
  <div class="nav-part mt-2">
    <p class="removing-1"><span>*</span> {{'MODALS.Device will no longer' | translate}}</p>
    <p class="removing-2">{{'MODALS.The device will also be' | translate}}</p>
  </div>


</mat-dialog-content>
<mat-dialog-actions class="center">
  <button mat-button mat-dialog-close class="blue-button">{{'ARCHIVINGPOPUPWINDOW.No' | translate}}</button>
  <button mat-button (click)="confirm()" class="red-button">{{'ARCHIVINGPOPUPWINDOW.Yes' | translate}}</button>
</mat-dialog-actions>


<!-- <div class="flex-column">
      <div class="delete-confirmation p-3">
        <h2 class="delete-confirmation-title m-0">{{'ARCHIVINGPOPUPWINDOW.Are you sure?' | translate}}</h2>
      </div>
      <div class="p-4">
        <p class="center text-center" *ngIf="customText == null; else elseTemplate">You will not be able to recover deleted entry.</p>
        <ng-template #elseTemplate>
          <p class="center text-center">
            {{customText}}
          </p>
        </ng-template>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="center pb-4 pt-0">
      <button mat-button mat-dialog-close class="blue-button">{{'ARCHIVINGPOPUPWINDOW.No' | translate}}</button>
      <button mat-button (click)="confirm()" class="red-button">{{'ARCHIVINGPOPUPWINDOW.Yes' | translate}}</button>
  </mat-dialog-actions> -->
