import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.translateLabels();
  }

  private translateLabels() {
    this.translate.get('PAGINATOR.items_per_page').subscribe((translation: string) => {
      this.itemsPerPageLabel = translation;
    });
    this.translate.get('PAGINATOR.next_page').subscribe((translation: string) => {
      this.nextPageLabel = translation;
    });
    this.translate.get('PAGINATOR.previous_page').subscribe((translation: string) => {
      this.previousPageLabel = translation;
    });
    this.translate.get('PAGINATOR.last_page').subscribe((translation: string) => {
      this.lastPageLabel = translation;
    });
    this.translate.get('PAGINATOR.first_page').subscribe((translation: string) => {
      this.firstPageLabel = translation;
    });
    this.getRangeLabel = this.customRangePage.bind(this);
  }

  private customRangePage(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
        return this.translate.instant('PAGINATOR.range_page_label_1', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.instant('PAGINATOR.range_page_label_2', { startIndex: startIndex + 1, endIndex, length });
  }
  
}
