import { BranchModel } from "./index";
import { CompanyModel } from './company.model';

export class UserModel {
  id: number;
  assigned_all_branches: boolean;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  password: string;
  status: boolean | string;
  role: string;
  logo: string;
  corporate_id: string;
  company_id: number;
  branch: BranchModel;
  branch_id: number;
  company: CompanyModel;
  language: string;
  lissa_roles: any[];
  lissa_modules: string[];
  user_branches: BranchModel[];
  user_roles: any[];
  read_only: boolean = true;
  two_factor_authentication: boolean;
  mfa: {
    active: boolean
  };
}
