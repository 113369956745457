import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BranchModel, BranchService, CountryModel } from '../..';
import { isFormDirtyAndChanged } from '../../helpers/form-dirty-validation';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-edit-branch-details',
  templateUrl: './edit-branch-details.component.html',
  styleUrls: ['./edit-branch-details.component.scss']
})
export class EditBranchDetailsComponent implements OnInit {
  @Input() branch: EventEmitter<BranchModel>;
  @Output() branchCreated = new EventEmitter<boolean>();
  public branchForm: FormGroup
  companyId: number;
  branchName: string;
  showInfo: boolean = false;
  errorMessage: string = '';
  countries: CountryModel[] = [];
  public initialFormData: any;
  public submitable: boolean = false;
  public parentBranches: BranchModel[] = [];


  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private branchService: BranchService,
    private router: Router,
    private toastr: ToastrService, 

  ) { 
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.buildBranchForm();
    this.branch.subscribe((branchInfo) => {
      this.showInfo =  branchInfo.branch ? true : false;
      this.branchName = branchInfo.branch?.name
      this.branchForm.patchValue(branchInfo.branch)
      this.companyId = branchInfo.company_id;
      this.getCountries();
      this.getParentBranches();
      this.initialFormData = this.branchForm.value;
    })
    this.branchForm.valueChanges
    .pipe( 
      debounceTime(500)
    )
    .subscribe(() => 
    this.submitable = isFormDirtyAndChanged(this.branchForm, this.initialFormData
    ))
  }

  public buildBranchForm(): void {
    this.branchForm = this.fb.group({
      id: [],
      name: [null, Validators.required],
      address_line_1: [null, Validators.required],
      address_line_2: [null],
      city: [null, Validators.required],
      zip: [null],
      country_id: [null],
      parent_branch_id: [null],   
    });
  }


  getParentBranches() {
    this.branchService.getBranches(this.companyId, '1', '', false, true)
      .subscribe(parentBranches => {
        this.parentBranches = parentBranches.rows;
      })
  }

  getCountries() {
    this.branchService.getCountries()
      .subscribe(countries =>this.countries = countries.rows);
  }
  saveBranch(){
    
    if (this.branchForm.valid) {
      this.branchService.updateBranch(
        this.companyId,
        this.branchForm.value)
        .subscribe(res => {
          this.branchCreated.emit(true);
          this.showInfo = false;
          this.toastr.success(this.translate.instant(`NOTIFICATIONS.Branch successfully updated`));
          this.router.navigate(["/branch/list"])
        })
      }
    }
    
}
