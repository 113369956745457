import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DeviceService, UserService } from '../../services';
import { DeviceBrandModel, DeviceModel, UserModel } from '../../models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { DeviceSupplierModel } from '../../models/device-supplier.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modify-device-spec',
  templateUrl: './modify-device-spec.component.html',
  styleUrls: ['./modify-device-spec.component.scss']
})
export class ModifyDeviceSpecComponent implements OnInit {
  public modifyDeviceSpec: FormGroup;
  public brands: DeviceBrandModel[] = [];
  public models: DeviceModel[] = [];
  public deviceSuppliers: DeviceSupplierModel[] = [];
  public currentUser = new UserModel();
  private deviceId: number;
  public device = new DeviceModel();
  public hasAtLeastOne: boolean;
  constructor(
    private fb: FormBuilder,
    private deviceService: DeviceService,
    @Inject(MAT_DIALOG_DATA) data: {route: ActivatedRoute},
    private dialogRef: MatDialogRef<ModifyDeviceSpecComponent>,
    private toastr: ToastrService,
    private userService: UserService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    data.route.params.subscribe(params => {this.deviceId = params.id});
   }

  ngOnInit() {
    this.modifyDeviceSpec = this.fb.group({
      imei: [],
      serial_number: [],
      device_supplier_id: []
    }, { validator: this.atLeastOne(Validators.required, ['serial_number', 'imei'])});
    this.getCurrentUser();
    this.getBrands();
    this.getModels();
    this.getDeviceSuppliers();
  }
  public atLeastOne = (validator: ValidatorFn, controls: string[] = null) => (
    group: FormGroup
  ): ValidationErrors | null => {
    if(!controls){
      controls = Object.keys(group.controls)
    }
    this.hasAtLeastOne = group && group.controls && controls
      .some(k => !validator(group.controls[k]));
    return this.hasAtLeastOne ? null : {
      atLeastOne: true,
    };
  }
  public getBrands(){
    this.deviceService.getBrands().subscribe( res => {
      this.brands = res.rows;
    })
  }

  public getModels(){
    this.deviceService.getModels().subscribe( res => {
      this.models = res.rows;
    })
  }
  public getCurrentUser(): void {
    this.userService.getCurrentUser().subscribe((user: UserModel) => {
      this.currentUser = user;
      this.getDevice();
    });
  }
  public getDeviceSuppliers(): void {
    this.deviceService.getDeviceSuppliers().subscribe( res => {
      this.deviceSuppliers = res.rows;
    })
  }


  public getDevice(): void {
    this.deviceService
      .getDevice(
        this.currentUser.company_id,
        this.currentUser.branch_id,
        this.deviceId
      )
      .subscribe((device: DeviceModel) => {
        this.device = device;
        this.modifyDeviceSpec.patchValue(this.device);
        // this.modifyDeviceSpec.controls["imei"].patchValue(this.device.imei);
        // this.modifyDeviceSpec.controls["serial_number"].patchValue(this.device.serial_number);
        // this.modifyDeviceSpec.controls["device_supplier_id"].patchValue(this.device.device_supplier.name);
      });
  }

  confirm() {
    if(this.modifyDeviceSpec.valid) {
      let data = {
        imei: this.modifyDeviceSpec.value.imei,
        serial_number: this.modifyDeviceSpec.value.serial_number,
        device_supplier_id: this.modifyDeviceSpec.value.device_supplier_id
      }
      this.dialogRef.close(data);
    } else {
      this.toastr.error(this.translate.instant('NOTIFICATIONS.Something went wrong, please try again.'))
    }
}

}
