import { SafeHtml } from '@angular/platform-browser';

export class DeviceTypeModel {
  id: number;
  name: string;
  status: boolean;
  icon: any;
  created_at: string;
  updated_at: string;
}
