import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BranchModel } from '../models';
import { ICO2Certificate } from '..';

@Injectable()
export class OrderService {
  private assetUrl = `${environment.assetUrl}/asset`;
  private statsSettingsSubject = new BehaviorSubject<{
    name: BranchModel;
    date: string;
    active: string;
  }>(null);
  statsSettings$ = this.statsSettingsSubject.asObservable();
  

  constructor(private http: HttpClient) { }

  setSettings(settings: { name: BranchModel; date: string; active: string }) {
    this.statsSettingsSubject.next(settings); // Push the new settings to all subscribers
  }

  getOrders(
    company_id: number,
    branch_id: number | string,
    page: number,
    limit: number,
    filterObject?: any,
  ): Observable<any> {
    let query = `?page=${page}&limit=${limit}`

    let params = new HttpParams();

    if (filterObject && Object.keys(filterObject).length) {
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
          params = params.set(k, filterObject[k]);
        }
      });
    }

    if (branch_id) {
      params = params.set("branch_id", branch_id.toString());
    }

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/order${query}`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getOrderDevices(
    company_id: number,
    order_number: number | string,
    page: number,
    limit: number,
    filterObject?: any,
  ): Observable<any> {
    let query = `?page=${page}&limit=${limit}`

    let params = new HttpParams();

    if (Object.keys(filterObject).length) {
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
          params = params.set(k, filterObject[k]);
        }
      });
    }

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/order/${order_number}${query}`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }


  getCO2Certificate(company_id: number, rent_number: string): Observable<any> {
    const headers = new HttpHeaders({
      Accept: "application/pdf, application/json",
    });

    return this.http
      .get(
        `${this.assetUrl}/company/${company_id}/order/documents/download?leaseScheduleId=${rent_number}`, {headers})
      .pipe(catchError((error: any) => throwError(error)));
  }

  getRents(
    company_id: number,
    branch_id?: number | string,
    // page: number,
    // limit: number,
    filterObject?: any,
  ): Observable<ICO2Certificate[]> {
    // let query = `?page=${page}&limit=${limit}`

    let params = new HttpParams();
    
    if (filterObject && Object.keys(filterObject).length) {
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
          params = params.set(k, filterObject[k]);
        }
      });
    }

    if (branch_id) {
      params = params.set("branch_id", branch_id.toString());
    }

    return this.http
      .get<ICO2Certificate[]>(`${this.assetUrl}/company/${company_id}/order/rent-numbers`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getRentDevices(
    company_id: number,
    branch_id: number | string,
    page: number,
    limit: number,
    rentNumber: string,
    serial_imei: string
  ): Observable<any> {
    let query = `?page=${page}&limit=${limit}`

    let params = new HttpParams();

    // if (Object.keys(filterObject).length) {
    //   Object.keys(filterObject).forEach(k => {
    //     if (filterObject[k] && typeof filterObject[k] != 'object') {
    //       params = params.set(k, filterObject[k]);
    //     }
    //   });
    // }

    if (branch_id) {
      params = params.set("branch_id", branch_id.toString());
    }

    if (serial_imei) {
      params = params.set("serial_imei", serial_imei.toString());
    }

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/order/rent/${rentNumber}/devices${query}`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getAvailableDocuments(company_id: number, order_number: string): Observable<any>{
    return this.http.get(`${this.assetUrl}/company/${company_id}/order/${order_number}/invoice-numbers`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getOrderInvoicePdf(company_id: number, invoiceNumber: string): Observable<any>{
    return this.http.get(`${this.assetUrl}/company/${company_id}/order/${invoiceNumber}/invoice-pdf`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getOrderDevicesInfo(company_id: number, orderNumber: string): Observable<any>{
    return this.http.get(`${this.assetUrl}/company/${company_id}/order/${orderNumber}/info`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getDevicesCountStats(
    company_id: number,
    branch_id: number | string,
    filterObject?: any,
  ): Observable<any> {

    let params = new HttpParams();

    if (Object.keys(filterObject).length) {
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
          params = params.set(k, filterObject[k]);
        }
      });
    }

    if (branch_id) {
      params = params.set("branch_id", branch_id.toString());
    }

    return this.http
    .get(`${this.assetUrl}/company/${company_id}/device/device-count`, {params})
      .pipe(catchError((error: any) => throwError(error)));
  }

}
