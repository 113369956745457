import { Injectable } from '@angular/core';
import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DeviceModel } from '../models';

@Injectable()
export class RobotService {
    private serviceUrl = `${environment.assetUrl}/service`;
    private assetUrl = `${environment.assetUrl}`;
    private actionUrl = `${environment.assetUrl}/asset`;
    constructor(private http: HttpClient) { }

    getLockers(page?: number, limit?: number, filtersObject?: any): Observable<any> {
        const query = `?page=${page}&limit=${limit}`;
        return this.http.get(`${this.serviceUrl}/locker${query}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getLockerById(locker_id): Observable<any> {
        return this.http.get(`${this.serviceUrl}/locker/${locker_id}`)
        .pipe(catchError((error: any) => throwError(error)));
    }
    createLocker(locker): Observable<any> {
        return this.http.post(`${this.serviceUrl}/locker`, locker)
        .pipe(catchError((error: any) => throwError(error)));
    }
    getAvailablesLockers(page?:number, limit?: number, employeeID?: number): Observable<any> {
      const query = `?page=${page}&limit=${limit}`
      return this.http.get(`${this.assetUrl}/asset/locker/available-lockers${employeeID ? '?employee_id=' + employeeID:  ''}${query ? query : ''}`)
      .pipe(catchError((error: any) => throwError(error)));
    }
    getAvailablesLockersForSwap(deviceId: number): Observable<any> {
      return this.http.get(`${this.assetUrl}/asset/locker/available-lockers-for-swap?device_id=${deviceId}`)
      .pipe(catchError((error: any) => throwError(error)));
    }
    getAvailablesDevices(page:number, limit:number, locker_id: number, filterObject?): Observable<any> {
      const query = `?page=${page}&limit=${limit}`
      let params = new HttpParams();
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object' && k !== 'device') {
            params = params.set(k, filterObject[k]);
        }
      })
      return this.http.get(`${this.assetUrl}/asset/locker/${locker_id}/available-devices${query}`, {params})
      .pipe(catchError((error: any) => throwError(error)));
    }
    getLockerPoolDevices(page, limit, filterObject?): Observable<any> {
      const query = `?page=${page}&limit=${limit}`
      let params = new HttpParams();
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object' && k !== 'device') {
            params = params.set(k, filterObject[k]);
        }
      })
      return this.http.get(`${this.assetUrl}/asset/locker/device${query}`, {params})
      .pipe(catchError((error: any) => throwError(error)));
    }
    getLockerDevices(
      page:number,
      limit:number,
      locker_id?: number,
      branch_id?:any,
      imei_serial?: string,
      include_action_needed?: boolean,
      include_pending_robot_actions?: boolean
      ): Observable<any>{
      let query = `?page=${page}&limit=${limit}`;
      if(branch_id){
        query += `&branch_id=${branch_id}`
      }
      if(imei_serial){
        query += `&imei_serial=${imei_serial}`
      }
      query += `&include_action_needed=${include_action_needed}`;
      query += `&include_pending_robot_actions=${include_pending_robot_actions}`;
      return this.http.get(`${this.assetUrl}/asset/locker/${locker_id}/search-devices${query}`)
      .pipe(catchError((error: any) => throwError(error)));
    }
    fillRobot(locker_id: number, device_ids: DeviceModel[], expires_at?: number):Observable<any> {
      let qParams = new HttpParams();
      qParams = qParams.append('action_expires_at', expires_at.toString());
      
      return this.http.post(`${this.assetUrl}/asset/locker/${locker_id}/fill`, {device_ids: device_ids}, {params: qParams})
      .pipe(catchError((error: any) => throwError(error)));
    }
    emptyRobot(locker_id: number, device_ids: DeviceModel[], expires_at?: number):Observable<any> {
      let qParams = new HttpParams();
      qParams = qParams.append('action_expires_at', expires_at.toString());

      return this.http.post(`${this.assetUrl}/asset/locker/${locker_id}/empty`, {device_ids: device_ids}, {params: qParams})
      .pipe(catchError((error: any) => throwError(error)));
    }
    returnDevicesToRobot(locker_id: number, device_ids: DeviceModel[], expires_at?: number):Observable<any> {
      let qParams = new HttpParams();
      qParams = qParams.append('action_expires_at', expires_at.toString());

      return this.http.post(`${this.assetUrl}/asset/locker/${locker_id}/return`, {device_ids: device_ids}, {params: qParams})
      .pipe(catchError((error: any) => throwError(error)));
    }
    swapRobotRequest(company_id: number, device_id: number, pool_device_id, expires_at?: number):Observable<any>{
      return this.http.post(`${this.assetUrl}/asset/company/${company_id}/device/${device_id}/swap-request`, {
        pool_device_id: pool_device_id, 
        action_expires_at: expires_at
      })
      .pipe(catchError((error: any) => throwError(error)));
    }
    getRobotActions(
      page:number,
      limit:number,
      filterObject?: any
      ): Observable<any> {
      let query = `?page=${page}&limit=${limit}`;
      let params = new HttpParams();
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
            params = params.set(k, filterObject[k]);
        }
      })
      return this.http.get(`${this.assetUrl}/asset/locker/action${query}`,{params})
      .pipe(catchError((error: any) => throwError(error)));
    }
  getActionById(actionID): Observable<any> {
      return this.http.get(`${this.actionUrl}/locker/action/${actionID}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  cancelRobotAction(action?): Observable<any> {
    return this.http.post(`${this.assetUrl}/integration/storetec/action/${action}/cancel`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }

  sendQrMail(action: number, email: string): Observable<any> {
    return this.http.post(`${this.assetUrl}/integration/storetec/action/${action}/send-mail?email=${email}`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }
  retriveAction(action: string, locker_id: number, device_ids: DeviceModel[], expires_at?: number): Observable<any> {
    switch (action) {
      case 'fill': 
        return this.fillRobot(locker_id, device_ids, expires_at)
      case 'return':
        return this.returnDevicesToRobot(locker_id, device_ids, expires_at)
      case 'empty':
        return this.emptyRobot(locker_id, device_ids, expires_at);
    }
  }
  extendRobotAction(action_id: number): Observable<any> {
    return this.http.post(`${this.actionUrl}/locker/action/${action_id}/extend`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }
  getLockerDevicesCount():Observable<any> {
    return this.http.get(`${this.assetUrl}/asset/locker/count-devices-in-lockers`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getLockersCount():Observable<any> {
    return this.http.get(`${this.assetUrl}/asset/locker/count-lockers`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  updateRobotNotifications(companyID: number, branchID: number, data): Observable<any> {
    return this.http.put(`${this.assetUrl}/asset/company/${companyID}/branch/${branchID}/locker-notifications`, data)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getRobotNotifications(companyID: number, branchID: number): Observable<any> {
    return this.http.get(`${this.assetUrl}/asset/company/${companyID}/branch/${branchID}/locker-notifications`)
    .pipe(catchError((error: any) => throwError(error)));
  }
}
