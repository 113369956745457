import { Component, EventEmitter, Input,OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, BranchModel, BranchService, EmployeeModel, EmployeeService, UserModel, UserService } from '../..';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { isFormDirtyAndChanged } from '../../helpers/form-dirty-validation';
import { debounce, debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-edit-employee-details',
  templateUrl: './edit-employee-details.component.html',
  styleUrls: ['./edit-employee-details.component.scss']
})
export class EditEmployeeDetailsComponent implements OnInit {
  @Input() employeeId: EventEmitter<any>;
  @Output() employeeUpdated = new EventEmitter<boolean>();
  showInfo = false;
  errorMessage = null;
  public branches: BranchModel[] = [];
  public selectedBranches: BranchModel[] = [];
  employeeForm: FormGroup;
  public employee = new EmployeeModel();
  currentUser = new UserModel();
  isManager;
  public initialFormData: any;
  public submitable: boolean = false;
  public hasAtLeastOne: boolean;
  public hasBoth: boolean;
  public hasPermission: boolean = false;

  constructor(
    private fb: FormBuilder,
    private branchService: BranchService,
    private auth: AuthenticationService,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private userService: UserService
    ) { }

  ngOnInit(): void {
    this.buildEmployeeForm();
    this.employeeId.subscribe(employee => {
      this.showInfo = employee ? true : false;
      let loggedUser = JSON.parse(localStorage.getItem('current_user'));
      this.currentUser = loggedUser?.user;
      this.hasPermission = this.userService.isAllowedToAccess(this.currentUser);
      this.isManager = this.userService.isManager(this.currentUser);
      this.getBranches();
      this.employeeForm.patchValue(employee);
      this.initialFormData = this.employeeForm.value;
    })


    this.employeeForm.valueChanges
    .pipe( 
      debounceTime(500)
    )
    .subscribe(() => {
      this.submitable = isFormDirtyAndChanged(this.employeeForm, this.initialFormData
        ) && this.hasAtLeastOne && this.hasBoth
      }) 

  }

  public buildEmployeeForm(): void {
    this.employeeForm = this.fb.group({
      id: [this.employee.id],
      first_name: [this.employee.first_name],
      last_name: [this.employee.last_name],
      email: [this.employee.email, [Validators.email, Validators.maxLength(75)]],
      phone_number: [this.employee.phone_number, Validators.pattern('[- +()0-9]+')],
      corporate_id: [this.employee.corporate_id],
      branch_id: [this.employee.branch?.id],
      cost_center: [],
      department: [],
      info: [],
    },{ validator: this.employeeValidator(Validators.required, ['first_name', 'last_name', 'corporate_id'])});
  }

  public employeeValidator = (validator: ValidatorFn, controls: string[] = null) => (
    group: FormGroup
    ): ValidationErrors | null => {
        if(!controls){
          controls = Object.keys(group.controls)
        }

        this.hasAtLeastOne = group && group.controls && controls
          .some(k => {
            return (!validator(group.controls["first_name"]) && !validator(group.controls["last_name"])) 
            || !validator(group.controls["corporate_id"])
       });

        this.hasBoth = !((group.controls["first_name"].value && !group.controls["last_name"].value) || 
                         (!group.controls["first_name"].value && group.controls["last_name"].value));

        return this.hasAtLeastOne ? null : {
          atLeastOne: true,
        };
    }

  public getBranches(): void {
    this.branchService.getBranches(
      this.currentUser.company_id == 1 && this.auth.sm_company_id ?
      this.auth.sm_company_id :
      this.currentUser.company_id, '1')
    .subscribe(response => {
      this.branches = response.rows;
    });
  }

  public isAllowed(user) {
    if (user?.user_roles || user?.lissa_roles) return true;
    return false;
  }
 
  updateEmployee(){
      this.employeeService.updateEmployee(
            this.currentUser.company_id,
            this.employeeForm.value
          ).subscribe(
        () => {
          this.toastr.success(this.translate.instant('NOTIFICATIONS.Employee data changed successfully.'))
          this.employeeUpdated.emit(true);
          this.router.navigate(["/employees/list"])
          this.showInfo = false;
        },
        error => {
          this.errorMessage =
            error.error != null
              ? error.error.message
              : "Something went wrong, please try again.";
              this.toastr.error(this.errorMessage);
        }
      );
  }

}
