import { Pipe, PipeTransform } from "@angular/core";
import { EmployeeModel, UserModel } from "../models";

@Pipe({ name: "fullname" })
export class FullNamePipe implements PipeTransform {
  transform(employee: EmployeeModel | UserModel): string {
    if (employee) {
      const fullname = `${employee?.first_name} ${employee?.last_name}`;
      return fullname;
    }
  }
}
