
  <div mat-dialog-content class="service-modal-dialog">
    <!-- <h2 class="center">Please stand the reason for archiving this device</h2> -->
    <div class="col-md-12 col-lg-12">
      <div class="service-modal-border">
        <h3 class="service-modal-title mb-0 p-3">SWAP device</h3>
      </div>
    </div>
    <div class="form-group row mx-3">
        <div class="col-md-12 col-lg-12 py-4">
          <div class="form-group">
            <div>
              <h3 class="service-modal-header">Choose an option for swapping device: </h3>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                [formControl]="swap_opt"
                class="status-list">
                <mat-radio-button class="example-radio-button" value="swap_pool">
                  Swap from Pool
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" value="swap_robot" *ngIf="robotsList.length > 0">
                  Swap from Robot
                </mat-radio-button>
                <div class="divider ml-3"></div>
              </mat-radio-group>
            </div>
            <div
              class="ml-3"
              [formGroup]="swapRobotForm"
              *ngIf="swap_opt.value == 'swap_robot'">
                <mat-radio-group
                aria-labelledby="example-radio-group-label"
                formControlName="robot_opt"
                class="status-list robot-opt">
                  <mat-radio-button class="robot-radio-btn" *ngFor="let robot of robotsList" [value]="robot?.id">
                    Robot: {{robot?.haai_id}} <br>
                    Location: {{robot?.address}}
                  </mat-radio-button>
                </mat-radio-group>
                <div class="col-12">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="email" formControlName="email" />
                  </mat-form-field>
                </div>
                <div class="row d-flex">
                  <div class="col-12 col-md-12">
                      <mat-form-field class="full-width">
                              <mat-label>{{'DEVICESWAP.Current device' | translate }}</mat-label>
                              <input matInput formControlName="device" placeholder="{{'DEVICESWAP.Current device' | translate }}"/>
                      </mat-form-field>
                      <div class="form-control-feedback" aria-hidden="true"></div>
                      <mat-error *ngIf="swapRobotForm.get('device').hasError('required')">
                            {{'DEVICESWAP.Device is' | translate }} <strong>{{'DEVICESWAP.required' | translate }}</strong>
                      </mat-error>
                  </div>
                </div>
                  <div class="row d-flex">
                      <div class="col-12 col-md-12">
                          <mat-form-field class="full-width">
                              <mat-label>{{'DEVICESWAP.Swap device' | translate }}</mat-label>
                              <input type="text" matInput formControlName="pool_device_id" [matAutocomplete]="poolDevices" />
                              <mat-autocomplete
                                autoActiveFirstOption
                                #poolDevices="matAutocomplete"
                                [displayWith]="poolDeviceAutocomplete"
                                  >
                                  <mat-option *ngFor="let device of poolDevicesList" [value]="device" class="device-serial-option">
                                      {{ device.serial_number ? device.serial_number : device.imei}}<span *ngIf="device?.imei" class="small-text">{{'/' + device?.imei}}</span>
                                      <span class="small-text"> {{device?.device_variant?.name}}</span>
                                  </mat-option>
                              </mat-autocomplete>
                              <mat-error *ngIf="swapRobotForm.get('pool_device_id').hasError('required')">
                                {{'DEVICESWAP.Device is' | translate }} <strong>{{'DEVICESWAP.required' | translate }}</strong>
                              </mat-error>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center" *ngIf="qrCode">
                    <div class="qrcode-img">
                      <img [src]="qrCode" alt="">
                    </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
  </div>
  <div mat-dialog-actions class="service-modal-buttons-toolbar mt-0 mb-2 mx-3">
    <button mat-button mat-dialog-close class="btn-link">Cancel</button>
    <div class="ml-auto">
      <button mat-button class="blue-button" *ngIf="!finished"(click)="swap()">SWAP</button>
      <button mat-button class="blue-button" *ngIf="finished" (click)="done()">Done</button>
    </div>
  </div>

