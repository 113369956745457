import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StyleConfiguratorService } from '../..';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  errorData;
  logoPath: string = '/assets/img/a1_logo.png';
  apiUrl: string;
  constructor(
    private location: Location,
    private confService: StyleConfiguratorService) { }

  ngOnInit(): void {
    this.errorData = this.location.getState();
    if (this.confService?.configuration?.configuration?.logo ) {
      this.apiUrl = environment.assetUrl;
      this.logoPath = `${this.apiUrl}/${this.confService.configuration.configuration.logo}`;
    }
  }

  onAppLeave() {
    localStorage.clear();
    window.location.href='https://daas.a1.net';
  }

}
