import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceModel, DeviceService, DeviceVariantModel, MobileProviderModel} from '../..';
import { BrandTypeModel } from '../../models/brand-type.model';
import { DeviceSupplierModel } from '../../models/device-supplier.model';
import {InsuranceSupplierModel} from '../../models/insurance-supplier.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { isFormDirtyAndChanged } from '../../helpers/form-dirty-validation';

declare var $: any;


@Component({
  selector: 'app-edit-device-details',
  templateUrl: './edit-device-details.component.html',
  styleUrls: ['./edit-device-details.component.scss']
})
export class EditDeviceDetailsComponent implements OnInit {
  @Input() deviceId: EventEmitter<any>;
  deviceForm: FormGroup;
  device: DeviceModel = new DeviceModel();
  brands: BrandTypeModel[] = [];
  deviceModels: DeviceModel[] = [];
  deviceVariants: DeviceVariantModel[] = [];
  deviceSuppliers: DeviceSupplierModel[] = [];
  deviceInsurance: InsuranceSupplierModel[] = [];
  public mobileProviders: MobileProviderModel[] = [];
  showInfo = false;
  hasSerial = false;
  hasImei = false;
  public initialFormData: any;
  public submitable: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private deviceService: DeviceService,
    private toastr: ToastrService,
    private translate: TranslateService
    ) { 
      translate.setDefaultLang('en');
    }

  ngOnInit() {
    this.buildDeviceForm();
    //this.deviceActivityColor(statusId);
    this.getMobileProviders();
    this.deviceId.subscribe(deviceId => {
      if (deviceId) {
        this.device = deviceId;
        this.showInfo = deviceId ? true : false;
        this.getDeviceBrands();
        this.deviceForm.patchValue({
          brand_id: deviceId?.device_variant?.device_model?.brand?.id,
          device_model_id: deviceId?.device_variant?.device_model?.id,
          device_variant_id: deviceId?.device_variant?.id,
          device_supplier_id: deviceId?.device_supplier?.id,
          start_price_id: deviceId?.device_price?.id,
          reference_number: deviceId?.device_price?.start_price,
          device_lifecycle: deviceId?.device_lifecycle,
          ...deviceId
        })
        Object.keys(this.deviceForm.controls).forEach(k => {
          if (this.deviceForm.controls[k].status === 'DISABLED' && !this.deviceForm.controls[k].value) {  
            this.deviceForm.controls[k].enable();
          }
        });
        this.initialFormData = this.deviceForm.value
        this.deviceForm.valueChanges.subscribe(() => {
          this.submitable = isFormDirtyAndChanged(this.deviceForm, this.initialFormData) && this.deviceForm.valid;
        })
      }
    });

    
  }

  public buildDeviceForm(): void {
    this.deviceForm = this.fb.group({
      id: [null],
      serial_number: [{value:null, disabled: true}],
      imei: [{value:null, disabled: true}],
      device_status_id: [1],
      device_variant_id: [null],
      branch_id: [null],
      company_id: [null],
      mobile_provider_id: [null],
      device_supplier_id: [null],
      insurance_id: [null],
      sim: [null],
      phone_number: [null, Validators.pattern('[- +()0-9]+')],
      brand_id: [null],
      device_model_id: [null],
      employee: [null],
      employee_id: [null],
      reference_number: [],
      additional_note: [],
      ticket_id: [],
      order_number: [],
      order_type: []
    });
  }


  public deviceStatusColor(statusId) {
    switch (statusId) {
      case 1:
        return 'text-primary';
      case 2:
        return 'text-success';
      case 3:
        return 'text-danger';
      case 4:
        return 'text-warning';
      case 5:
        return 'text-default';
      case 6:
        return 'text-loan';
      case 7:
        return 'text-eol';
      case 8:
        return 'text-archive';
      case 9:
        return 'text-danger';
    }
  }

  getDeviceBrands() {
    this.deviceService.getBrandTypes(this.device.device_type.id)
    .subscribe(brands => {
      this.brands = brands
      this.getDeviceModels();
      this.getDeviceVariants();
      this.getDeviceSupplier();
      this.getDeviceInsurance();      
    });
  }

  getDeviceModels(brandId?) {
    const brand = brandId ? brandId : this.deviceForm.value.brand_id;
    this.deviceService.getDeviceModels(brand, this.device.device_type.id)
    .subscribe(models => this.deviceModels = models.rows);
  }

  getDeviceVariants(brandId?: number, modelId?: number) {
    const brand = brandId ? brandId : this.deviceForm.value.brand_id; 
    const model = modelId ? modelId : this.deviceForm.value.device_model_id; 
    this.deviceService.getDeviceVariants(brand, model)
    .subscribe(variants => this.deviceVariants = variants.rows);
  }

  getDeviceSupplier() {
    this.deviceService.getDeviceSuppliers()
    .subscribe(supplier => this.deviceSuppliers = supplier.rows);
  }

  getDeviceInsurance() {
    this.deviceService.getInsurances()
    .subscribe(insurance => this.deviceInsurance = insurance.rows)
  }
  getMobileProviders(): void {
    this.deviceService.getMobileProviders().subscribe(response => {
      this.mobileProviders = response.rows;
    });
  }

  confirm() {
    this.deviceService.updateDevice(
      this.device.company_id, 
      this.device.branch_id, 
      this.device.id, 
      this.deviceForm.value).subscribe(res => {
          if(res) {
          this.deviceService.deviceUpdated.emit(true);
          this.toastr.success(this.translate.instant(`NOTIFICATIONS.Device successfully updated`));
          this.showInfo = false;
        } 
      }, error => {
        if (error?.error?.message) {
          this.toastr.error('', error?.error?.message);
          }
        }
      );

      const data = this.deviceForm.value;
      this.deviceService.updateDevice(this.device.company_id, this.device.branch_id, this.device.id, data);
  }
}
