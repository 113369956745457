import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inactive-modal',
  templateUrl: './inactive-modal.component.html',
  styleUrls: ['./inactive-modal.component.scss'],
  providers: [TranslateService]
})
export class InactiveModalComponent implements OnInit {
  customText: string = '';
  employee: string;
  device: string;
  user: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<InactiveModalComponent>,
    private translate: TranslateService
  ) {
    this.customText = data != null ? data.text : null;
    this.employee = data.employee;
    this.device = data.device;
    this.user = data.name
    translate.setDefaultLang('en');
  }

  ngOnInit() {
  }

  confirm() {
    this.dialogRef.close(true);
  }

}
