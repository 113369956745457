import { InsuranceSupplierModel } from './insurance-supplier.model';

export class InsuranceModel {
    id: number;
    name: string;
    insurance_supplier: InsuranceSupplierModel;
    created_at: string;
    updated_at: string;
    deviceCount: number;
  }
  