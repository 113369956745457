<mat-dialog-content class="flex-column">
  <!-- <div>
      <i class="pe-7s-trash center"></i>
  </div> --> 
 
  <div class="nav">  
      <h1>Confirm 2 way authentication</h1>  <span mat-button mat-dialog-close class="x-icon"><img src="../../../../assets/icons/x-button.png"></span>
    </div>  
  <div class="nav-part">
      <p class="m-0 py-3">Mobile Number Verified</p>
      <div class="col-12 col-md-5 pt-20">
        <mat-checkbox formControlName='mobileChecked' labelPosition="right">Verified</mat-checkbox>
    </div>
  </div>
  <div class="nav-part">
    <p class="m-0 py-3" *ngIf="mobileNumber">{{mobileNumber}}</p>
    <mat-form-field class="mobile-number" appearance="outline">
        <input matInput type="mobileNumber" placeholder="mobile number" formControlName="mobileNumber">
    </mat-form-field>
</div>

  <div class="remove-class">
      <div class="first" *ngIf="user">
          <h2>User: </h2>  <span>{{user}}</span>
      </div>
  </div>
 
</mat-dialog-content>
<mat-dialog-actions class="center">
  <button mat-button mat-dialog-close class="cancel-btn">cancel</button>
  <button mat-button   class="btn btn-primary btn-update">confirm</button>
</mat-dialog-actions>
