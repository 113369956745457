import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ServiceCasesModel } from '../../models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CancelDeviceScComponent } from '../../modal-dialogs/cancel-device-sc/cancel-device-sc.component';
import { BreakAndFixService, DeviceService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.scss']
})
export class ServiceInfoComponent implements OnInit {
  @Input() serviceId: EventEmitter<any>;
  @Input() openCases: EventEmitter<boolean>;
  @Input() icons: any = [];
  public serviceCase: ServiceCasesModel;
  public showInfo: boolean = false;
  public allSteps: any[] = [];
  public cancelEnabled: boolean = true;
  public deviceSCModal = new MatDialogConfig();


  constructor(private dialog: MatDialog,
    private deviceService: DeviceService,
    private toastr: ToastrService,
    private breakAndFixService: BreakAndFixService,
    private translate: TranslateService,) { }

  ngOnInit(): void {

    this.serviceId.subscribe(service => {
      this.serviceCase = service;
      this.showInfo = service ? true : false;

      if (Array.isArray(this.serviceCase.service_step_completeds)) {
        this.cancelEnabled = this.serviceCase.service_step_completeds.slice(-1)[0].service_step_id < 4;
      }

      if (this.serviceCase?.resolution === "repaired") {
        this.allSteps = this.generateAllSteps();
      }
    })

  }

  public cancelServiceCase() {
    this.deviceSCModal.width = '792px';
    this.deviceSCModal.height = 'auto';
    this.deviceSCModal.data = {
      heading: this.translate.instant('CANCELSERVICECASEPOPUP.Cancel Service Case'),
      text: this.translate.instant('CANCELSERVICECASEPOPUP.Are you sure'),
      device: this.serviceCase.device,
      service_ticket_id: this.serviceCase.ticket_id
    }
    const canceletionModal = this.dialog.open(CancelDeviceScComponent, this.deviceSCModal);
    canceletionModal.afterClosed().subscribe(res => {
      if (res) {
        this.deviceService.cancelServiceStep(this.serviceCase.device?.company_id, this.serviceCase.device?.id, this.serviceCase.id)
          .subscribe(res => {
            this.toastr.success('', this.translate.instant('NOTIFICATIONS.Service canceled'));
            this.breakAndFixService.setSCCancel(true);
          }, error => {
            this.toastr.error('', error.error.message);
          });
          this.showInfo = false;
      }
    });
  }


  generateAllSteps(): any[] {
    const allSteps = [];
    if (this.icons.length > 6) {
      this.icons.splice(-5);
    }

    this.icons.forEach(icon => {
      if (Array.isArray(this.serviceCase.service_step_completeds)) {
        const foundStep = this.serviceCase.service_step_completeds.find(step => step.service_step_id === icon.id);

        if (foundStep) {
          allSteps.push(foundStep);
        } else {
          allSteps.push({
            service_step_id: icon.id,
            completed_at: this.serviceCase.service_step_completeds[this.serviceCase.service_step_completeds.length - 1].completed_at
          });
        }
      }
    });

    return allSteps;
  }



}
