import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { DeviceService } from "../..";
import { Enums } from "../../enums/lists.enum";


@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"]
})
export class DeleteModalComponent implements OnInit {
  public customText: string = null;
  public deviceName: string = null;
  public deviceSerial: string = null;
  reasons: Observable<string[]>;
  employee: string;
  uploadedFile: File[] = [];
  public archiveForm: FormGroup;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<DeleteModalComponent>,
    private fb: FormBuilder,
    private enums: Enums,
    private deviceService: DeviceService
  ) {
    this.customText = data != null ? data.text : null;
    this.deviceName = data.device.name ? data.device.name : null;
    this.deviceSerial = data.device.serial ? data.device.serial : null;
    this.employee = data?.device?.employee;
  }

  ngOnInit() {
    this.archiveForm = this.fb.group({
      reason: [],
      note: [],
      file: []
    })
    this.getArchiveReasons();
  }

  getArchiveReasons() {
   this.reasons = this.deviceService.getArchiveReasons();
  }
  handleUpload(file){
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.archiveForm.patchValue({file: reader.result})
    };
  }

  onFileUpload(event) {
    this.handleUpload(event.addedFiles[0])
    this.uploadedFile.push(...event.addedFiles);
  }
  onRemove(event) {
    this.uploadedFile.splice(this.uploadedFile.indexOf(event), 1);
  }

  confirm() {
    this.dialogRef.close(this.archiveForm.value);
  }
}
