
    
    <div>
        <p>{{'ACTIONNEEDEDRESOLVEPOPUP.Action resolve comment' | translate}} </p>
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'ACTIONNEEDEDRESOLVEPOPUP.Comment' | translate}}</mat-label>
            <textarea matInput rows="5" [formControl]="comment"></textarea>
        </mat-form-field>
        <button class="btn btn-primary d-flex ml-auto" (click)="confirm()">{{'ACTIONNEEDEDRESOLVEPOPUP.CONFIRM' | translate}}</button>
    </div>
   

