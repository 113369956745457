<mat-dialog-content class="flex-column">
    <!-- <div>
        <i class="pe-7s-trash center"></i>
    </div> -->
   
   
    <div class="nav">  
        <h1>{{'MODALS.Confirm status change' | translate}}</h1>  <span mat-button mat-dialog-close class="x-icon"><img src="../../../../assets/icons/x-button.png"></span>
      </div>  
    <div class="space-line"></div>


    <div class="nav-part">
        <!-- <h2 class="title">{{'MODALS.Are you sure?' | translate}}</h2> -->
        <!-- <p class="removing">Removing user will return current device to the pool..</p> -->
        <p class="m-0 py-3" *ngIf="customText">{{customText}}</p>
    </div>
    <div class="space-line"></div>
    <div class="remove-class">
        <div class="first" *ngIf="user">
            <h2>{{'MODALS.User' | translate}}: </h2>  <span>{{user}}</span>
        </div>
    </div>
    <!-- <div class="remove-class">
        <div class="first">
            <h2>Remove employee: </h2><span> {{employee}} </span>
        </div>
        <div class="second">
            <span>from</span>
        </div>
        <div class="third">
            <h2>Device: </h2><span>{{device}}</span>
        </div>
    </div> -->
</mat-dialog-content>
<mat-dialog-actions class="center">
    <button mat-button mat-dialog-close class="cancel-btn">{{'MODALS.No' | translate | titlecase}}</button>
    <button mat-button (click)="confirm()" class="red-button">{{'MODALS.Change' | translate | titlecase}}</button>
</mat-dialog-actions>
