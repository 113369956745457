import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BreakAndFixService {
  private assetUrl = `${environment.assetUrl}/asset`;
  private isSCCancelled: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) { }


  setSCCancel(value: boolean): void {
    this.isSCCancelled.next(value);
  }

  getSCCancel(): Observable<boolean> {
    return this.isSCCancelled.asObservable();
  }

  getServiceCases(
    company_id: number,
    branch_id: number | string,
    page: number,
    limit: number,
    type: boolean,
    filterObject?: any,
  ): Observable<any> {
    let query = `?page=${page}&limit=${limit}`

    let params = new HttpParams();

    if (Object.keys(filterObject).length) {
      Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object' && k !== 'device') {
          params = params.set(k, filterObject[k]);
        }
      });
    }

    if (branch_id) {
      params = params.set("branch_id", branch_id.toString());
    }

    params = params.set("completed", type.toString());

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/break-and-fix/service-cases${query}`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getServiceCaseStatistics(
    company_id: number,
    branch_id?: number
  ): Observable<any> {
    let params = new HttpParams();

    if (branch_id) {
      params = params.set("branch_id", branch_id.toString());
    }

    return this.http
      .get(`${this.assetUrl}/company/${company_id}/break-and-fix/statistics`, {
        params,
      })
      .pipe(catchError((error: any) => throwError(error)));
  }

  exportServiceCases(company_id:number, type: boolean, filterObject?): Observable<any> {
    let params = new HttpParams();
    Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
            params = params.set(k, filterObject[k]);
        }
    });

    params = params.set("completed", type.toString());
    
    return this.http.get(`${this.assetUrl}/company/${company_id}/break-and-fix/export`, {params})
    .pipe(catchError((error: any) => throwError(error)));
  }
}
