import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "amountFormated" })
export class AmountFormtedPipe implements PipeTransform {
    transform(amount: string): string {
        if (amount !== null) {
            let decimalCount = 2;
            let decimal = ",";
            let thousands = "."
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = +amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substring(0, j) + thousands : '') + i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(+amount - +i).toFixed(decimalCount).slice(2) : "");
        } else {
            return "-";
        }
    }
}