import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from '../../models';

@Component({
  selector: 'app-action-needed',
  templateUrl: './action-needed.component.html',
  styleUrls: ['./action-needed.component.scss']
})
export class ActionNeededComponent implements OnInit {
  @Input() actionNeededDevices: number ;
  @Input() currentUser: UserModel;

  constructor() { }

  ngOnInit(): void {
  }

}
