import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-archive-device',
  templateUrl: './archive-device.component.html',
  styleUrls: ['./archive-device.component.scss']
})
export class ArchiveDeviceComponent implements OnInit {
  public archiveDeviceForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ArchiveDeviceComponent>,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
   }

  ngOnInit() {
    this.archiveDeviceForm = this.fb.group({
      note: []
    })
  }
  confirm() {
    this.dialogRef.close(this.archiveDeviceForm.value);
  }

}
