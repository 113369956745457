import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services';
import { UserModel } from '../../models';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-modify-details',
  templateUrl: './modify-details.component.html',
  styleUrls: ['./modify-details.component.scss']
})
export class ModifyDetailsComponent implements OnInit {
  currentUser = new UserModel();
  modifyAccountForm: FormGroup;
  logo: File[] = [];
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ModifyDetailsComponent>,
    private toastr: ToastrService,
    private userService: UserService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.modifyAccountForm = this.fb.group({
      name: [null, Validators.required],
      address_line_1: [null, Validators.required],
      address_line_2: [null],
      city: [null, Validators.required],
      zip: [null, Validators.required],
      logo: []
    })
    this.getCurrentUser();
  }
  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((user: UserModel) => {
      this.currentUser = user;
      this.modifyAccountForm.patchValue(this.currentUser.company);
    })
  }

  confirm() {
      if(this.modifyAccountForm.valid) {
        let data = {
          name: this.modifyAccountForm.value.name,
          address_line_1: this.modifyAccountForm.value.address_line_1,
          address_line_2: this.modifyAccountForm.value.address_line_2,
          city: this.modifyAccountForm.value.city,
          zip: this.modifyAccountForm.value.zip,
          logo: this.modifyAccountForm.value.logo
        }
        this.dialogRef.close(data);
      } else {
        this.toastr.error(this.translate.instant('NOTIFICATIONS.Something went wrong, please try again'))
      }
  }

  handleUpload(file){
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        this.modifyAccountForm.controls['logo'].patchValue(reader.result);
    };
  }
  onCoverChange(event) {
    this.handleUpload(event.addedFiles[0])
    this.modifyAccountForm.controls['logo'].patchValue(event.addedFiles[0].name);
    this.logo.push(...event.addedFiles);
  }
  onRemove(event) {
    console.log(event);
    this.logo.splice(this.logo.indexOf(event), 1);
  }

}
