<div *ngIf="serviceCase?.resolution || openCases">
    <div [ngClass]="{'backdrop': showInfo}" (click)="showInfo=false"></div>
    <div class="panel-wrap" [ngClass]="{'panel-show': showInfo}">
        <div class="panel">
            <div class="nav">
                <h1 *ngIf="serviceCase?.resolution" class="mb-0"> {{serviceCase?.resolution === 'repaired' ? ('BREAKANDFIX.Repair' | translate |
                    uppercase) : ('BREAKANDFIX.Swap' | translate | uppercase)}}</h1>
                    <h1 *ngIf="!serviceCase?.resolution" class="mb-0"> {{('BREAKANDFIX.Overview' | translate | uppercase)}}</h1> 
                    <span (click)="showInfo=false"><img
                        src="../../../../assets/icons/x-button.png"></span>
            </div>
            <div class="divider"></div>
            <div class="info-container">
                <div class="general-info">
                    <h2>{{'BREAKANDFIX.General information' | translate | titlecase}}</h2>
                    <div class="content">
                        <div><span>{{'BREAKANDFIX.Service case' | translate}}: </span> {{serviceCase?.ticket_id}}</div>
                        <div><span>{{'BREAKANDFIX.Created by' | translate}}: </span>{{serviceCase?.user?.first_name }}
                            {{serviceCase?.user?.last_name}}</div>
                        <div><span>{{'BREAKANDFIX.Created at' | translate}}: </span> {{serviceCase?.started_at |
                            dateFormat: 'DD. MMMM YYYY.'}}</div>
                        <div><span>{{'BREAKANDFIX.Resolved at' | translate}}: </span>{{serviceCase?.ended_at |
                            dateFormat: 'DD. MMMM YYYY.'}}</div>
                        <div class="divider"></div>
                        <div><span>{{'BREAKANDFIX.Model' | translate}}:
                            </span>{{serviceCase?.device?.device_variant?.device_model?.brand?.name}},
                            {{serviceCase?.device?.device_variant?.memory}},
                            {{serviceCase?.device?.device_variant?.color}}</div>
                        <div><span>{{'BREAKANDFIX.Serial number' | translate | titlecase}}:
                            </span>{{serviceCase?.device?.serial_number}}</div>
                        <div><span>IMEI:</span> {{serviceCase?.device?.imei}}</div>
                        <br />
                        <div *ngIf="serviceCase?.resolution === 'swapped'" class="swapped-info">
                            <h2>{{'BREAKANDFIX.Swapped with' | translate}}</h2>
                            <div><span>{{'BREAKANDFIX.Model' | translate}}:</span>
                                {{serviceCase?.additional_info?.swapped_device?.name}}</div>
                            <div><span>{{'BREAKANDFIX.Serial number' | translate | titlecase}}:</span>
                                {{serviceCase?.additional_info?.swapped_device?.serial_number}}</div>
                            <div><span>IMEI:</span> {{serviceCase?.additional_info?.swapped_device?.imei}}</div>
                            <div><span>{{'BREAKANDFIX.Lifecycle' | translate}}:</span> {{'BREAKANDFIX.Started new
                                lifecycle from' | translate}} {{serviceCase?.ended_at | dateFormat: 'DD. MMMM YYYY.'}}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- TO DO: insurance info -->
                <!-- <div class="divider" *ngIf="false"></div>
                <div class="insurance-info" *ngIf="false">
                    <h2>{{'BREAKANDFIX.Insurance and warranty info' | translate}}</h2>
                    <div class="content">
                        <div><span>{{'BREAKANDFIX.Insurance type' | translate}}:</span> HAAI Care</div>
                        <div><span>{{'BREAKANDFIX.Service coverage' | translate}}:</span> Garantie / Migar</div>
                        <div><span>{{'BREAKANDFIX.Insurance start to end date' | translate}}:</span> 08.07.2022. -
                            08.07.2022.</div>
                        <div><span>{{'BREAKANDFIX.Warranty start to end date' | translate}}:</span> 08.07.2022. -
                            08.07.2022.</div>
                    </div>
                </div> -->

                <div class="divider"></div>
                <div class="user-and-company-info">
                    <h2>{{'BREAKANDFIX.User and company info' | translate | titlecase}}</h2>
                    <div class="content">
                        <div>
                            <span>{{ 'BREAKANDFIX.Employee' | translate }}:</span>
                            {{ serviceCase?.device?.employee ? (serviceCase.device.employee.first_name + ' ' +
                            serviceCase.device.employee.last_name) : 'N/A' }}
                        </div>
                        <div><span>{{'BREAKANDFIX.Reporter' | translate}}:</span> {{serviceCase?.user?.first_name}}
                            {{serviceCase?.user?.last_name}}</div>
                        <div><span>{{'BREAKANDFIX.Company' | translate}}:</span> {{serviceCase?.company?.name}}</div>
                        <div><span>{{'BREAKANDFIX.Address' | translate}}:</span>
                            {{serviceCase?.company?.address_line_1}}</div>
                        <div>
                            <span>{{ 'BREAKANDFIX.Contact email' | translate }}:</span>
                            {{ serviceCase?.device?.employee?.email || 'N/A' }}
                        </div>
                    </div>
                    <br />
                    <h2 >{{'BREAKANDFIX.Delivery information' | translate
                        | titlecase}}</h2>
                    <div  class="content">
                        <div><span>{{'BREAKANDFIX.Address' | translate | titlecase}}:</span>
                            {{serviceCase?.device?.branch?.address_line_1 || 'N/A'}}</div>
                        <div><span>{{'BREAKANDFIX.City' | translate | titlecase}}:</span>
                            {{serviceCase?.device?.branch?.city || 'N/A'}}</div>
                        <div><span>{{'BREAKANDFIX.ZIP' | translate | titlecase}}:</span>
                            {{serviceCase?.device?.branch?.zip || 'N/A'}}</div>
                    </div>
                    <div class="divider"></div>
                    <h2 >{{'BREAKANDFIX.Break and fix issues' | translate
                        | titlecase}}</h2>
                    <div  class="content">
                        <div><span>{{'BREAKANDFIX.Issue description' | translate | titlecase}}:</span>
                            {{serviceCase?.service_document?.description}}.</div>
                        <div><span>{{'BREAKANDFIX.Visual condition' | translate | titlecase}}:</span>
                            {{serviceCase?.service_document?.condition}}</div>
                        <div><span>{{'BREAKANDFIX.Accessories' | translate}}:</span>
                            {{serviceCase?.service_document?.accessories ? ('BREAKANDFIX.Yes' | translate) :
                            ('BREAKANDFIX.No' | translate) }}</div>
                        <div *ngIf="false" class="accessories-pills">
                            <div class="pill">{{"Charger" | uppercase}}</div>
                            <div class="pill">{{"Packaging" | uppercase}}</div>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="additional-info">
                    <h2>{{'BREAKANDFIX.Additional notice' | translate | titlecase}}</h2>
                    <div class="content">
                        <div><span>{{'BREAKANDFIX.Notice' | translate}}:</span> {{serviceCase?.service_document?.note ||
                            '/'}}</div>
                    </div>
                    <br />
                    <h2 *ngIf="serviceCase?.resolution === 'repaired'">{{'BREAKANDFIX.Device activity timeline' |
                        translate}}</h2>
                    <div *ngIf="serviceCase?.resolution === 'repaired'" class="timeline-container">
                        <div class="device_activity">
                            <div *ngFor="let step of allSteps; let i = index" class="card">
                                <div class="card-block">
                                    <div class="timeline">
                                        <div class="timeline-entry">
                                            <div class="timeline-status">
                                                <img src="../../../../assets/icons/timeline_inactive.svg" alt="">
                                            </div>
                                            <div class="timeline-label ">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="case-title">
                                                            <mat-icon class="material-icons-outlined step-icon"
                                                                alt="">{{(serviceCase |
                                                                lastCompletedStepInfo:icons:step.service_step_id)?.icon}}</mat-icon>
                                                            <p>
                                                                {{(serviceCase |
                                                                lastCompletedStepInfo:icons:step.service_step_id)?.text
                                                                | translate}}
                                                            </p>
                                                        </div>
                                                        <p>{{'BREAKANDFIX.Date' | translate}}: {{step?.completed_at |
                                                            dateFormat: 'DD.MM.YYYY.'}}</p>
                                                        <p *ngIf="step?.service_step_id === 1">{{'BREAKANDFIX.Created
                                                            by' | translate}}: {{serviceCase?.user?.first_name }}
                                                            {{serviceCase?.user?.last_name}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-nav">
                <button *ngIf="cancelEnabled && !serviceCase?.cancelled" (click)="cancelServiceCase()" class="btn btn-primary">
                    {{'DEVICEDETAILS.Cancel Service Case' | translate | titlecase}} 
                </button>
                <button (click)="showInfo = false" class="btn btn-cancel">
                    {{'MODALS.Close' | translate | titlecase}}
                </button>
            </div>
        </div>
    </div>
</div>