import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, BranchModel, BranchService, DashboardService, TranslationService, UserModel, UserService } from 'src/app/shared';
import { startWith, debounceTime, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public currentUser: UserModel = new UserModel();
  public userGuide: boolean = false;
  public branches: BranchModel[] = [];
  public branch_id = 0;
  public filterForm: FormGroup;
  public filteredBranches: Subscription;
  public hasPermission: boolean = false;
  public internalUser;

  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private branchService: BranchService,
              private dashboardService: DashboardService,
              private route: Router,
              public translate: TranslateService,
              private translationService: TranslationService,
              private fb: FormBuilder
              ) {
              }

  ngOnInit() {
    this.getCurrentUser();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'de') { this.translationService.selectedLang = event.lang; }
      else {this.translationService.selectedLang = ""}
    });
    this.dashboardService.branchIdChanged.subscribe(branch_id => {
      if (branch_id === 0) {
        // this.branch_id = '0';
      } else {
        if (typeof branch_id === 'object') {
          this.filterForm.patchValue({name: branch_id});
        }
        this.dashboardService.branchIdSelected = this.filterForm.value?.name?.id;
      }
    });
    this.dashboardService.branchCreated.subscribe(created => {
      this.getBranches();
    });
    this.filterForm = this.fb.group({
      active: ['1'],
      name: []
    });

    this.initFilters();

    this.internalUser = this.authService.getToken();

  }

  public logout(): void {
    this.authService.logout();
  }

  public getCurrentUser(): void {
    this.userService.getCurrentUser().subscribe(user => {
      if (this.authService.headers?.user && !this.authService?.headers?.ext_sm) {
        const userPayload = {user: {...user?.body}}
        localStorage.setItem('current_user', JSON.stringify(userPayload)) 
      }
      this.currentUser = user.body;
      this.translate.addLangs(['en', 'de']);
      this.translate.setDefaultLang(this.currentUser.language ? this.currentUser.language : 'en');
      this.translate.use(this.currentUser.language);
      this.hasPermission = this.userService.isAllowedToAccess(user?.body);

      if (this.currentUser?.user_roles.includes('manager')) {
        this.branches = user.body.user_branches.map(branch => {
          return branch?.branch;
         });
        } else {
        this.getBranches();
      }

    });
  }
  public getBranches() {
    this.branchService.getBranches(this.currentUser.company_id, '1', '', true).subscribe(res => {
      this.branches = res.rows;
    });
  }
  public isAtDashboard(): boolean {
    return window.location.href.indexOf('dashboard') !== -1;
  }

  public emitReloadDashboardEvent(): void {
    if (!this.filterForm.value?.name?.id) {
      this.clearFilter('');
    }
    this.dashboardService.branchIdSelected = this.filterForm.value?.name?.id;
    this.dashboardService.branchIdChanged.emit(this.filterForm.value?.name?.id);
  }
  public clearFilter(val?) {
    if (val === '') this.dashboardService.branchIdChanged.emit(this.branch_id);
  }
  initFilters(){
    this.filteredBranches = this.filterForm.controls.name.valueChanges
    .pipe(startWith(null))
    .pipe(debounceTime(500))
    .pipe(
      tap(val => {
        if (val && typeof val !== 'object') {
          this.branches = [];
          this.branchService
            .getBranchesPerPage(
              this.currentUser.company_id, 1, 100000, this.filterForm.value
            )
            .subscribe(response  => {
              if (response.rows.length !== 0) {
                this.branches = response.rows;
              } else {

              }
            });
        } else if (val === '') {
          this.emitReloadDashboardEvent();
          this.getCurrentUser();
        }
      })
    )
    .subscribe();
  }
  branchAutocompleteView(branch?: BranchModel): string | undefined {
    if (branch) {
      return `${branch.name}`;
    } else {
      return undefined;
    }
  }
}
