import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {
  
  mobileNumber: number = null;
  employee: string;
  device: string;
  user: string;
  mobileChecked: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<TwoFactorAuthenticationComponent>,
  ) {
    this.mobileNumber = data != null ? data.text : null;
    this.employee = data.employee;
    this.device = data.device;
    this.user = data.name;
    this.mobileChecked = data.mobileChecked;
   }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close(true);
  }

}

  