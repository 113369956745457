import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeviceModel, RobotModel } from '../../models';
import { startWith, debounceTime, tap } from 'rxjs/operators';
import { RobotService } from '../..';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service-tool-modal',
  templateUrl: './service-tool-modal.component.html',
  styleUrls: ['./service-tool-modal.component.scss']
})
export class ServiceToolModalComponent implements OnInit {
  swap_opt = new FormControl();
  swapRobotForm: FormGroup;
  public robotsList: RobotModel[] = [];
  public filteredPoolDevices: Subscription;
  public poolDevicesList: DeviceModel[] = [];
  public device = new DeviceModel();
  public user_id;
  public finished: boolean = false;
  public errorMessage: string;
  qrCode;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ServiceToolModalComponent>,
    private translate: TranslateService,
    private fb: FormBuilder,
    private robotService: RobotService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {
    translate.setDefaultLang('en');
    this.device = data.device;
    this.user_id = data.currentUser;
  }
  ngOnInit() {
    this.swapRobotForm = this.fb.group({
      robot_opt: [],
      email: [],
      device: [],
      pool_device_id: []
    })
    this.getAvailableRobots();
    this.initFilters();
    this.swapRobotForm.patchValue({device: `${this.device.serial_number ? this.device.serial_number : 'N/A'}${this.device.imei ? '/' + this.device.imei : ''}`})
  }

  initFilters() {
    this.filteredPoolDevices = this.swapRobotForm.controls["pool_device_id"].valueChanges
    .pipe(startWith(null))
    .pipe(debounceTime(500))
    .pipe(
      tap(val => {
        if (val && typeof val != "object") {
          this.poolDevicesList = [];
          this.robotService.getLockerDevices(
              1,
              100,
              this.swapRobotForm.value.robot_opt,
              this.device.branch_id,
              val
              )
            .subscribe(response => {
              if (response.rows.length != 0) {
                this.poolDevicesList = response.rows;
              } else {
              }
            }, error => {
              if (error){
                this.errorMessage = error.error;
                // this.toastr.error("", this.errorMessage);
              }
            });
        } else if (val == "") {
          this.poolDevicesList = [];
        }
      })
    )
    .subscribe();
}

poolDeviceAutocomplete(device?: DeviceModel): string | undefined {
  if (device) {
    return device.serial_number ? device.serial_number : device.imei;
  } else {
    return undefined;
  }
}
  getAvailableRobots() {
    this.robotService.getAvailablesLockersForSwap(this.device?.id)
      .subscribe((robot) => {
        this.robotsList = robot;
      });
  }
  done() {
    this.dialogRef.close();
  }
  swap() {
    const swapForm = this.swapRobotForm.value;
    if (this.swap_opt.value === 'swap_robot') {
      this.robotService.swapRobotRequest(
        this.user_id,
        this.device.id,
        this.swapRobotForm.value.pool_device_id.id
      ).subscribe(res => {
        this.qrCode = this.sanitizer.bypassSecurityTrustResourceUrl(`${res?.qrCode64}`);
        this.finished = true;
        this.robotService.sendQrMail(res.action_id, this.swapRobotForm.value.email).subscribe(()=> {});
        this.toastr.success("",this.translate.instant('NOTIFICATIONS.Swap request for robot created'));
      }, error => {
        if(error.error) {
          this.errorMessage = `Device ${swapForm.pool_device_id.serial_number ?
            swapForm.pool_device_id.serial_number :
            swapForm.poo_device_id.imei} has an unresolved locker action.`
          this.toastr.error("", this.errorMessage);
        }
      })
    } else {
      this.dialogRef.close(this.swap_opt.value);
    }

  }

}
