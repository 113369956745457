import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RobotModel } from '../../models';
import { RobotService } from '../../services';

@Component({
  selector: 'app-robot-modal',
  templateUrl: './robot-modal.component.html',
  styleUrls: ['./robot-modal.component.scss']
})
export class RobotModalComponent implements OnInit {
  robotForm: FormGroup;
  robotsList: RobotModel[] = [];
  robot: RobotModel = new RobotModel();
  email = new FormControl();
  days = 7;
  count;
  action: string;
  public fillRequest;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<RobotModalComponent>,
    private robotService: RobotService,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.fillRequest = data?.fill_request;
    this.robot = data?.robot;
    this.count = data?.count;
    this.action = data?.action;
    this.translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.getAvailableRobots();
    this.buildRobotForm();
  }

  buildRobotForm() {
    this.robotForm = this.fb.group({
      robot_id: []
    })
  }
 
  getAvailableRobots(){
    this.robotService.getAvailablesLockers(1, 200)
      .subscribe((robot) => {
        this.robotsList = robot.rows;
      });
  }
  confirm(type?: string) {
      type === 'email' ?
      this.dialogRef.close({email: this.email.value, expires_at: this.days}) :
      this.dialogRef.close({robot: this.robotForm.value, expires_at: this.days});
  }
}
