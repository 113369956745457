
<mat-dialog-content class="flex-column">
 
  <div class="nav">  
    <h1>Are you sure to delete Category ?</h1>  <span mat-button mat-dialog-close class="x-icon ml-3"><img src="../../../../assets/icons/x-button.png"></span>
  </div>      
  <div class="space-line"></div>

  <div class="d-flex">

    
  </div>
  <div class="nav-part mt-2 center">
    <p class="removing-2 d-flex justify-content-center">This will delete Category</p>
  </div>


</mat-dialog-content>
<mat-dialog-actions class="center justify-content-center">
<button mat-button mat-dialog-close class="blue-button">No</button>
<button mat-button (click)="confirm()" class="red-button">Yes</button>
</mat-dialog-actions>

 