import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-action-needed-comment',
  templateUrl: './action-needed-comment.component.html',
  styleUrls: ['./action-needed-comment.component.scss']
})
export class ActionNeededCommentComponent implements OnInit {
  comment: FormControl = new FormControl();
  constructor(
    private dialogRef: MatDialogRef<ActionNeededCommentComponent>,

  ) { }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close({comment: this.comment.value});
  }

}
