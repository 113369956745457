import { CompanyModel } from './company.model';
import { DeviceModel } from './device.model';
import { EmployeeModel } from './employee.model';
import { UserModel } from './user.model';

export class ServiceCasesModel {
    company_id: number;
    company: CompanyModel;
    user: UserModel;
    created_at: string;
    ticket_id: string;
    device: DeviceModel;
    device_id: number;
    ended_at: string;
    service_document: ServiceDocumentModel;
    id: number;
    service_step_completeds: ServiceStepCompletedModel;
    started_at: string;
    user_id: number;
    cancelled: boolean;
    resolution: string;
}
export class ServiceStepCompletedModel {
    id: number;
    service_case_id: number;
    service_step: ServiceStepModel;
    service_step_id: number;
}

export class ServiceStepModel {
    id: number;
    blocker: boolean;
    name: string;
    nav_code: string;
    show: boolean;
    step_number: number;
}

export class ServiceDocumentModel {
    contact_email: string;
    created_at: string;
    description: string;
    employee: EmployeeModel;
    id: number;
    note: string;
    service_case_id: number;
}
