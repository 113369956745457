<form [formGroup]="updateBranchForm" (ngSubmit)="confirm()">
    <div mat-dialog-content >
      <div class="ng-template-text">
        <h2 class="center change-status-title">{{customText}}</h2>
        <span mat-dialog-close><img src="../../../../assets/icons/x-button.png"></span>
      </div>
      <div class="form-group row">
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <p class="current-branch">{{'CHANGEBRANCH.Current Branch' | translate}}: <span class="span_text">{{branch?.name}}</span></p>
            </div>
            <div class="form-group">
              <span class="choose-new-branch">{{'CHANGEBRANCH.Choose New Branch' | translate}}</span>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'BRANCHFORM.New Branch' | translate }}</mat-label>
                <mat-select placeholder="{{'CHANGEBRANCH.New Branch' | translate}}" formControlName="branch_id">
                  <mat-option *ngFor="let branch of branchesList" [value]="branch?.id">
                    {{branch?.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <span class="comment">
             {{'CHANGEBRANCH.Additional Notice' | translate}} <p class="optional-title">{{'CHANGEBRANCH.Optional' | translate}}</p>
            </span>
            <app-additional-comment (commentEmmiter)="getComment($event)"></app-additional-comment>
          </div>
      </div>
    </div>
    <div mat-dialog-actions class="example-button-row">
      <button  mat-dialog-close class="btn cancel-btn mr-1 ml-auto">{{'BRANCHFORM.Cancel' | translate | titlecase}}</button>
      <button (click)="confirm()" [disabled]="!submitable" class="btn btn-primary">{{'CHANGEBRANCH.CHANGE BRANCH' | translate | titlecase}}</button>
    </div>
</form>
