import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { UserService, UserModel, ImagesService, DashboardService, FeaturesModel, AuthenticationService } from 'src/app/shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public currentUser: UserModel = new UserModel();
  public images;
  public featuresList: FeaturesModel[] = [];
  public hovered: boolean = false;
  public internalUser;
  public externalUser;
  public hasPermission: boolean = false;

  constructor(
    private userService: UserService,
    private imageService: ImagesService,
    private router: Router,
    private features: DashboardService,
    public translate: TranslateService,
    private authService: AuthenticationService,
    ) {
        translate.setDefaultLang('en');
      }

  ngOnInit() {
    this.images = this.imageService.imgSrc;
    this.getCurrentUser();
    this.internalUser = this.authService.getToken();
  }

  public getCurrentUser(): void {
    this.currentUser = this.userService.loggedUser;
    this.hasPermission = this.userService.isAllowedToAccess(this.currentUser);
    this.externalUser = this.authService.headers;
    this.getFeatures();
  }
  public getFeatures(): void {
    this.features.getLatestFeatures().subscribe(features => {
      this.featuresList = features;
    });
  }
 
  onAddNew(event: Event, path: string) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate([path]);
  }
  
  public logout(): void {
    this.internalUser ? 
      this.authService.logout() :
      // window.location.href = 'https://daas-test.a1.net' // test;
      window.location.href = 'https://daas.a1.net' // prod;
  }
}
