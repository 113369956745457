import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabulatorService {
  private tabSettings: { [key: string]: { selectedIndex: number } } = {};

  constructor() { }

  getSelectedTab() {
    return JSON.parse(sessionStorage.getItem(`tabulator`));
  }

  setSelectedTab(tabKey: string, selectedIndex: number): void {
    this.tabSettings[tabKey] = {
      selectedIndex
    }
    const storedSettings = JSON.parse(sessionStorage.getItem('tabulator')) || {};
    const newSettings = { ...storedSettings, ...this.tabSettings };
    sessionStorage.setItem('tabulator', JSON.stringify(newSettings));
  }

  clearSelection() {
    this.tabSettings = {};
  }
}
