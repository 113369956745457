import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs'; 
import { CreateBranchCategoriesComponent } from '../../components/create-branch-categories/create-branch-categories.component';
import { ClientCategoryService } from '../../services';

@Component({
  selector: 'app-are-you-sure-modal',
  templateUrl: './are-you-sure-modal.component.html',
  styleUrls: ['./are-you-sure-modal.component.scss']
})
export class AreYouSureModalComponent implements OnInit {
  public createBranchCategory: CreateBranchCategoriesComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<AreYouSureModalComponent>,
    private clientCategoryService : ClientCategoryService,
  ) {
     
  }

  ngOnInit() {
   
  }
  
  confirm() { 
    this.dialogRef.close(true);
  }

}
