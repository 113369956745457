import { Injectable, Renderer2, Inject, RendererFactory2, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScriptLoaderService {
  private rendered: Renderer2;

  constructor(
      private renderedFactory: RendererFactory2, 
      @Inject(DOCUMENT) 
      private document: Document,
      private ngZone: NgZone
      ) {
    this.rendered = this.renderedFactory.createRenderer(null, null);
  }

  loadScript(scriptUrl: string, attributes: { [key: string]: string }, callback: () => void): void {
    const script = this.rendered.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptUrl;

    // Set attributes
    for (const key in attributes) {
      if (attributes.hasOwnProperty(key)) {
        script.setAttribute(key, attributes[key]);
      }
    }
    
    if (script.readyState) { // old IE support
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else { // modern browsers
      script.onload = () => {
        callback();
        this.dispatchCustomDOMContentLoaded();
      };
    }

    this.rendered.appendChild(this.document.getElementsByTagName('head')[0], script);
  }

  private dispatchCustomDOMContentLoaded(): void {
    // Use NgZone.runOutsideAngular to avoid triggering change detection unnecessarily
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const DOMContentLoaded_event = this.document.createEvent('Event');
        DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
        this.document.dispatchEvent(DOMContentLoaded_event);
      });
    });
  }

  loadAssetJiraWidget(callback: () => void): void {
    const attributes = {
      'data-jsd-embedded': null,
      'data-key': '41df0438-b9d2-4487-a707-95f18dbd3253',
      'data-base-url': 'https://jsd-widget.atlassian.com'
    };

    this.loadScript('https://jsd-widget.atlassian.com/assets/embed.js', attributes, callback);
  }

  loadA1JiraWidget(callback: () => void): void {
    const attributes = {
      'data-jsd-embedded': null,
      'data-key': '0bad372c-9fb6-4e86-ac8c-12de873b0e2b',
      'data-base-url': 'https://jsd-widget.atlassian.com'
    };

    this.loadScript('https://jsd-widget.atlassian.com/assets/embed.js', attributes, callback);
  }
}