import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class DashboardService {
  private assetUrl = `${environment.assetUrl}/asset`;
  private adminUrl = `${environment.assetUrl}/admin`;
  public branchIdChanged: EventEmitter<any>;
  public branchCreated: EventEmitter<any> = new EventEmitter();
  public branchIdSelected: any;

  constructor(private router: Router, private http: HttpClient) {
    this.branchIdChanged = new EventEmitter();
  }

  getDevicesValue(company_id: number, branch_id: number): Observable<any> {
    const query = branch_id !== null ? `?branch_id=${branch_id}` : ``;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device/value${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDevicesCount(company_id: number, branch_id: number | string): Observable<any> {
    const query = branch_id  ? `?branch_id=${branch_id}` : ``;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device/count${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getDevicesStatus(company_id: number, branch_id: number | string): Observable<any> {
    const query = branch_id  ? `?branch_id=${branch_id}` : ``;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device/status${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getExpiringDevices(company_id: number, branch_id: number | string): Observable<any> {
    const query = branch_id ? `?branch_id=${branch_id}` : ``;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/device/expiring_devices_count${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getExpiredDevices(company_id, branch_id: number | string): Observable<any> {
    const query = branch_id ? `?branch_id=${branch_id}` : ``;
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/expired_devices_count${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getLatestFeatures(): Observable<any> {
    return this.http.get(`${this.adminUrl}/notification`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getActionNeededDevices(company_id: number, branch_id?: number): Observable<any> {
    let query = branch_id ? `?branch_id=${branch_id}`: '';
    
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/action-needed-count${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getDashboardStats(company_id: number, branch_id?: number | string): Observable<any> {
    let query = branch_id ? `?branch_id=${branch_id}`: '';
    return this.http.get(`${this.assetUrl}/company/${company_id}/dashboard/stats${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
}
