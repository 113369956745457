import { Pipe, PipeTransform } from "@angular/core";
import { ActionNeeded, DeviceModel } from "..";

@Pipe({ name: "hasRobotAction"})
export class HasRobotAction implements PipeTransform {

    transform(device: DeviceModel, excludeInLockerCheck: boolean): boolean {
        
        if (!device) {
            return false;
        }

        const hasStoretecDeviceLocker = !!device.storetec_device_locker;
        const hasOutgoingDevice = Array.isArray(device.outgoing_device) ? device.outgoing_device.length > 0 : !!device.outgoing_device;
        const hasIncomingDevice = Array.isArray(device.incoming_device) ? device.incoming_device.length > 0 : !!device.incoming_device;
        const hasStoretecDeviceIos = Array.isArray(device.storetec_device_ios) ? device.storetec_device_ios.length > 0 : !!device.storetec_device_ios;
        const hasPendingDeviceIOS = device?.pendingDeviceIOs > 0;
        const hasUnresolvedAction = !!device?.action_neededs?.filter((action: ActionNeeded) => !action?.resolved && action?.type.startsWith('locker-'))[0];

        
        if (excludeInLockerCheck) {
            return hasOutgoingDevice || hasIncomingDevice || hasStoretecDeviceIos || hasPendingDeviceIOS || hasUnresolvedAction;
        } else {
            return hasStoretecDeviceLocker || hasOutgoingDevice || hasIncomingDevice || hasStoretecDeviceIos || hasPendingDeviceIOS || hasUnresolvedAction;
        }
        
    }
}