<div *ngIf="type === 'regular'"  class="regular">
    <small>€</small>{{numberPart}}<small>,{{decimalPart }}</small>
</div>

<div *ngIf="type === 'current'"  class="current">
    <small>€</small>{{numberPart}}<small>,{{decimalPart}}</small>
</div>

<div *ngIf="type === 'initial'" class="initial">
    <small>€</small>{{numberPart}}<small>,{{decimalPart}}</small>
</div>