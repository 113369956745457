import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceModel, ServiceStepCompletedModel } from '../..';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-action-needed-resolver',
  templateUrl: './action-needed-resolver.component.html',
  styleUrls: ['./action-needed-resolver.component.scss']
})
export class ActionNeededResolverComponent implements OnInit {
  resolveActionForm: FormGroup;
  device: DeviceModel;
  action: ServiceStepCompletedModel;
  actionType: string;
  costs;
  files: File[] = [];
  pdfFile: Blob;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ActionNeededResolverComponent>,
  ) { 
    this.action = data?.action;
    this.device = data?.device;
    this.costs = data?.action?.additional_info;
    this.actionType = this.action?.service_step?.name.split(' ')[0];
    if (data?.file && !data?.file.includes('<error>S')) {
      this.readBase64File(data?.file);
    }
  }

  ngOnInit(): void {
    this.buildResolveActionForm();
  }
  buildResolveActionForm() {
    this.resolveActionForm = this.fb.group({
      response: [],
      reason: [],
      info: [],
      file:[]
    })
  }

onSelect(event) {
  this.handleFileUpload(event.addedFiles[0])
  this.files.push(...event.addedFiles);
}
handleFileUpload(file) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    this.resolveActionForm.controls.file.patchValue(reader.result);
  };
}
onRemove(file) {
  this.files.splice(this.files.indexOf(file), 1);
}
readBase64File(file) {
  const decodedFile = Uint8Array.from(
    atob(file)
      .split('')
      .map(char => char.charCodeAt(0))
  );
  const blob = new Blob([decodedFile], { type: 'application/pdf'});
  this.pdfFile = blob;
}
saveFile() {
  saveAs(this.pdfFile, 'cost');
}
confirm(){
    const form = this.resolveActionForm.value;
    let cost = {
      'Yes': this.costs?.repair_cost,
      'No': this.costs?.diagnosis_cost,
      'Disposal': this.costs?.scrap_cost
    }
    
    Object.keys(form).forEach(key => form[key] == null && delete form[key])
    this.dialogRef.close({
        action: this.action,
        cost: cost[form.response],
        ...form
      });
  }
}
